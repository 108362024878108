import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-tier2-form',
	templateUrl: './tier2-form.component.html',
	styleUrls: ['./tier2-form.component.css'],
})
export class Tier2FormComponent {
	@Input() tier2Form: any;
}
