// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
	position: absolute;
	display: none;
	z-index: 100;
	top: 0;
	left: 0;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.5);
	width: 100%;
	height: 100%;
}
.loader.loading {
	display: flex;
	justify-content: center;
	align-items: center;
}

ul.ds-c-list label {
	flex-direction: column;
}

ul.ds-c-list label > * {
	width: 100%;
	margin-right: auto;
}

.public-des {
	position: relative;
	bottom: 24px;
}

@supports (-moz-appearance: none) {
	.public-des {
		top: 0.2em;
	}
}

.hide-tag {
	display: none;
}

.disabled-checkbox,
.disabled-label {
	opacity: 0.5;
	pointer-events: none;
}

.disabled-button {
	cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/list-serve/signup/listserv-signup.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,aAAa;CACb,YAAY;CACZ,MAAM;CACN,OAAO;CACP,uBAAuB;CACvB,0CAA0C;CAC1C,WAAW;CACX,YAAY;AACb;AACA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,YAAY;AACb;;AAEA;CACC;EACC,UAAU;CACX;AACD;;AAEA;CACC,aAAa;AACd;;AAEA;;CAEC,YAAY;CACZ,oBAAoB;AACrB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".loader {\n\tposition: absolute;\n\tdisplay: none;\n\tz-index: 100;\n\ttop: 0;\n\tleft: 0;\n\tjustify-content: center;\n\tbackground-color: rgba(255, 255, 255, 0.5);\n\twidth: 100%;\n\theight: 100%;\n}\n.loader.loading {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\nul.ds-c-list label {\n\tflex-direction: column;\n}\n\nul.ds-c-list label > * {\n\twidth: 100%;\n\tmargin-right: auto;\n}\n\n.public-des {\n\tposition: relative;\n\tbottom: 24px;\n}\n\n@supports (-moz-appearance: none) {\n\t.public-des {\n\t\ttop: 0.2em;\n\t}\n}\n\n.hide-tag {\n\tdisplay: none;\n}\n\n.disabled-checkbox,\n.disabled-label {\n\topacity: 0.5;\n\tpointer-events: none;\n}\n\n.disabled-button {\n\tcursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
