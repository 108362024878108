import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'app-vmap-succes',
	templateUrl: './vmap-succes.component.html',
	styleUrls: ['./vmap-succes.component.css'],
})
export class VmapSuccesComponent implements OnInit {
	vmapID: any;
	constructor(
		private router: Router,
		public activatedRoute: ActivatedRoute,
		private titleService: Title
	) {}

	ngOnInit() {
		this.titleService.setTitle('Successfully Submitted');
		this.activatedRoute.paramMap
			.pipe(
				tap(() => {
					this.vmapID = window.history.state?.vmapId;
					if (!this.vmapID) this.router.navigate(['']);
				})
			)
			.subscribe();
	}
}
