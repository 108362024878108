// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Override CMS design system */
.ds-c-label {
	max-width: none;
	font-weight: 400;
}

.ds-c-field {
	max-width: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/hospitals-at-home/waiver/point-of-contact/point-of-contact.component.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;CACC,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,eAAe;AAChB","sourcesContent":["/* Override CMS design system */\n.ds-c-label {\n\tmax-width: none;\n\tfont-weight: 400;\n}\n\n.ds-c-field {\n\tmax-width: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
