import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APP_CONFIG, IAppEnvConfig } from '@qnp/qnp-common';
import { Observable } from 'rxjs';

@Injectable()
export class VaccineAttestationService {
	constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: IAppEnvConfig) {}

	publicContentApiUrl = `${this.config.publicGateway}${this.config.publicContentApi}`;
	//publicContentApiUrl = `http://localhost:3000/public`;

	submitVaccineAttestationRequest(payload, path): Observable<any> {
		const url = `${this.publicContentApiUrl}/vmap/${path}`;
		return this.http.post<any>(url, payload);
	}

	uploadFile(preSignedUrl, file: File): Observable<null> {
		return this.http.put<null>(preSignedUrl, file);
	}
}
