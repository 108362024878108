import { isEmpty } from 'lodash';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	AbstractControl,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { VaccineAttestationService } from '../../services/vaccine-attestation.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { tap } from 'rxjs/operators';
import { FacilityTypes } from './vaccine-attestation.model';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from '@qnp/qnp-common';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-vaccine-attestation',
	templateUrl: './vaccine-attestation.component.html',
	styleUrls: ['./vaccine-attestation.component.css'],
})
export class VaccineAttestationComponent implements OnInit {
	VaccineAttestationForm: UntypedFormGroup;

	isCaptchaValid = false;
	reCaptchaToken: string;
	@ViewChild('recaptcha') public recaptchaElem: RecaptchaComponent;

	files: any = {};
	isLoading = false;
	facilityTypes = FacilityTypes;
	activeFormType: 'nursingHome' | 'acc';
	constructor(
		public formBuilder: UntypedFormBuilder,
		private vaccineAttSvc: VaccineAttestationService,
		private titleService: Title,
		private toastr: ToastrService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.initializeFormGroup();
		const facilityTypeControl = <UntypedFormControl>this.VaccineAttestationForm.get('facilityType');
		facilityTypeControl.valueChanges
			.pipe(
				tap(value => {
					if (value === 'Long Term Care Facilities') {
						this.VaccineAttestationForm.addControl(
							'listOfResidents',
							new UntypedFormControl('', [Validators.required])
						);
					} else {
						this.VaccineAttestationForm.removeControl('listOfResidents');
					}
					if (!this.VaccineAttestationForm.contains('vaccinationFormByType')) {
						const formGroup =
							value === 'Long Term Care Facilities'
								? this.initializeNursingHomeForm()
								: this.initializeAccForm();
						this.activeFormType = value === 'Long Term Care Facilities' ? 'nursingHome' : 'acc';
						this.VaccineAttestationForm.addControl('vaccinationFormByType', formGroup);
						return;
					}
					if (
						this.VaccineAttestationForm.contains('vaccinationFormByType') &&
						value === 'Long Term Care Facilities' &&
						this.activeFormType === 'acc'
					) {
						this.VaccineAttestationForm.removeControl('vaccinationFormByType');
						this.VaccineAttestationForm.addControl(
							'vaccinationFormByType',
							this.initializeNursingHomeForm()
						);
						this.activeFormType = 'nursingHome';
					}
					if (
						this.VaccineAttestationForm.contains('vaccinationFormByType') &&
						value !== 'Long Term Care Facilities' &&
						this.activeFormType === 'nursingHome'
					) {
						this.VaccineAttestationForm.removeControl('vaccinationFormByType');
						this.VaccineAttestationForm.addControl(
							'vaccinationFormByType',
							this.initializeAccForm()
						);
						this.activeFormType = 'acc';
					}
				})
			)
			.subscribe();
	}

	private initializeFormGroup() {
		this.VaccineAttestationForm = this.formBuilder.group({
			facilityType: new UntypedFormControl('', [Validators.required]),
			pointOfContact: this.initializePointOFContactForm(),
			pocFax: new UntypedFormControl('', [
				Validators.pattern(`\\(?\\d{3}\\)?-? *\\d{3}-? *-?\\d{4}`),
			]),
			pocTitle: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'/.\\s]*$`),
			]),
			attestation: this.initializeAttestationForm(),
			attestationStatement: new UntypedFormControl('', [Validators.required]),
			vaccinationPolicies: new UntypedFormControl('', [Validators.required]),
			vaccinationStatus: new UntypedFormControl('', [Validators.required]),
		});
	}

	private initializeAccForm() {
		return new UntypedFormGroup({
			iq1a: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'.\\s]*$`),
			]),
			ccn: new UntypedFormControl('', [
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(6),
			]),
			iq1d: new UntypedFormControl('', [Validators.required]),
			iq1e: new UntypedFormControl('', [Validators.required, this.maxNumberValidator]),
			pq1: new UntypedFormControl('', [Validators.required]),

			pq3: new UntypedFormControl('', [Validators.required]),

			pq5: new UntypedFormControl('', [Validators.required]),

			pq9: new UntypedFormControl('', [Validators.required]),

			pq11: new UntypedFormControl('', [Validators.required]),
			pq11a: new UntypedFormControl('', [Validators.required]),
			pq11a1: new UntypedFormControl('', [Validators.required]),
			pq11a2: new UntypedFormControl('', [Validators.required]),
			pq12: new UntypedFormControl('', [Validators.required]),
			pq13: new UntypedFormControl('', [Validators.required]),
			pq14: new UntypedFormControl('', [Validators.required]),
			pq15: new UntypedFormControl('', [Validators.required]),
			pq16: new UntypedFormControl('', [Validators.required]),
			pq17: new UntypedFormControl('', [Validators.required]),
			pq18: new UntypedFormControl('', [Validators.required]),
			pq19: new UntypedFormControl('', [Validators.required, this.percentageValidator]),
			pq20: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			pq21: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			pq22: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			pq23: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			pq24: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
		});
	}

	maxNumberValidator = (control: AbstractControl) => {
		return control.value > 99999999999999 ? { invalidLength: true } : null;
	};

	private initializeNursingHomeForm() {
		return new UntypedFormGroup({
			iq1a: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'.\\s]*$`),
			]),
			iq1b: new UntypedFormControl('', [Validators.required]),
			ccn: new UntypedFormControl('', [
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(10),
			]),
			iq1d: new UntypedFormControl('', [Validators.required]),
			iq1g: new UntypedFormControl('', [Validators.required, this.maxNumberValidator]),
			eq1a: new UntypedFormControl('', [Validators.required]),
			eq2a: new UntypedFormControl('', [Validators.required]),
			eq4a: new UntypedFormControl('', [Validators.required]),
			eq5a: new UntypedFormControl('', [Validators.required]),
			eq6a: new UntypedFormControl('', [Validators.required]),
			eq7b: new UntypedFormControl('', [Validators.required]),
			eq7c: new UntypedFormControl('', [Validators.required]),
			eq7d: new UntypedFormControl('', [Validators.required]),
			eq8a: new UntypedFormControl('', [Validators.required]),
			eq9a: new UntypedFormControl('', [Validators.required]),
			eq11a: new UntypedFormControl('', [Validators.required]),
			eq12a: new UntypedFormControl('', [Validators.required]),
			eq16a: new UntypedFormControl('', [Validators.required, this.percentageValidator]),
			eq16b: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			eq16c: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			eq16d: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			eq16e: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			eq16f: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			eq17a: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			eq18a: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
			eq19a: new UntypedFormControl('', [Validators.required, this.facilityDeatilsValidator]),
		});
	}

	facilityDeatilsValidator = (control: AbstractControl) => {
		return `${control.value}`.length > 15 ? { invalidLength: true } : null;
	};
	percentageValidator = (control: AbstractControl) => {
		if (!control.value) return null;
		const percentageRegex = /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/;
		return !percentageRegex.test(control.value) ? { invalidPercentile: true } : null;
	};

	initializePointOFContactForm() {
		return new UntypedFormGroup({
			pocName: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'/.\\s]*$`),
			]),
			pocEmail: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$`),
			]),
			pocConfirmEmail: new UntypedFormControl('', [
				Validators.required,
				this.emailMatchConfirmValidator,
			]),
			pocPhone: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern(`\\(?\\d{3}\\)?-? *\\d{3}-? *-?\\d{4}`),
			]),
			pocPhoneExt: new UntypedFormControl('', [Validators.pattern(`\\d{4}`)]),
		});
	}

	initializeAttestationForm() {
		return new UntypedFormGroup({
			notes: new UntypedFormControl('', [
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			attestingName: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'/.\\s]*$`),
			]),
			attestingEmail: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$`),
			]),
			attestingPhone: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern(`\\(?\\d{3}\\)?-? *\\d{3}-? *-?\\d{4}`),
			]),
			attestingTitle: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
			otherTitle: new UntypedFormControl('', [
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'.\\s]*$`),
			]),
		});
	}

	emailMatchConfirmValidator = () => {
		const pointOfContactForm = this?.VaccineAttestationForm?.controls[
			'pointOfContact'
		] as UntypedFormGroup;
		return pointOfContactForm?.controls['pocEmail']?.value ===
			pointOfContactForm?.controls['pocConfirmEmail']?.value
			? null
			: { mismatch: true };
	};

	resolved(captchaResponse: string) {
		this.isCaptchaValid = !isEmpty(captchaResponse);
		this.reCaptchaToken = captchaResponse;
	}

	onFileChange(event, type?) {
		this.files[type] = event.target.files[0];
	}

	omitNumber(event) {
		const key = event.which;
		if (key == 43 || key == 45 || key == 101) return false;
	}

	submitRequest() {
		const filesData = [];
		for (let file in this.files) {
			const fileData = {
				fileMetaType: file,
				fileName: this.files[file].name,
			};
			filesData.push(fileData);
		}
		const payload = {
			recaptchaToken: this.reCaptchaToken,
			providerType: this.activeFormType === 'nursingHome' ? 'Nursing Homes' : 'ACC',
			facilityType: this.VaccineAttestationForm.value.facilityType,
			...this.VaccineAttestationForm.value.pointOfContact,
			...this.VaccineAttestationForm.value.attestation,
			pocFax: Number(this.VaccineAttestationForm.value.pocFax),
			pocTitle: this.VaccineAttestationForm.value.pocTitle,
			...this.VaccineAttestationForm.value.vaccinationFormByType,
			filesData,
		};
		delete payload?.notes;
		delete payload?.otherTitle;
		const conditionalQuestions =
			this.activeFormType === 'nursingHome'
				? ['eq5b', 'eq6b', 'eq8b', 'eq9b']
				: ['pq2', 'pq4', 'pq6', 'pq10'];
		conditionalQuestions.forEach(question => {
			if (!payload[question]) {
				payload[question] = null;
			}
		});
		this.isLoading = true;
		const path = this.activeFormType === 'nursingHome' ? 'nh' : 'acc';
		this.vaccineAttSvc.submitVaccineAttestationRequest(payload, path).subscribe(
			response => {
				this.uploadFiles(response.s3Urls, response.vmapRequestNumber);
			},
			err => {
				this.isLoading = false;
				this.toastr.error('Unable to submit request. Please try again!');
			}
		);
	}

	private uploadFiles(s3Urls, vmapRequestNumber) {
		const uploadFileReqs = [];
		s3Urls.forEach(s3Url => {
			const file = this.files[s3Url.fileMetaType];
			const req = this.vaccineAttSvc.uploadFile(s3Url.url, file);
			uploadFileReqs.push(req);
		});
		forkJoin([...uploadFileReqs]).subscribe(
			response => {
				const navigationExtras: NavigationExtras = {
					state: { vmapId: vmapRequestNumber },
				};
				this.isLoading = false;
				this.router.navigate(['vaccine-requirement-attestation-success'], navigationExtras);
			},
			err => {
				this.isLoading = false;
				this.toastr.error('Unable to submit request. Please try again!');
			}
		);
	}
}
