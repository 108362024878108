// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
	text-align: center;
	margin: 20px;
	font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/search/search/search.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,YAAY;CACZ,eAAe;AAChB","sourcesContent":[".loading {\n\ttext-align: center;\n\tmargin: 20px;\n\tfont-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
