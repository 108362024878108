/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG, IAppEnvConfig } from '@qnp/qnp-common';

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(ref => {
		const appConfig: IAppEnvConfig = ref.injector.get(APP_CONFIG);
		if (appConfig.production) {
			enableProdMode();
			if (window) {
				window.console.log = function () {}; // disable console logs in production
			}
		}
	})
	.catch(err => console.log(err));
