import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-hospitals-at-home-search',
	templateUrl: './hospital-search.component.html',
	styleUrls: ['./hospital-search.component.css'],
})
export class HospitalSearchComponent implements OnInit {
	hospitalSearchForm: UntypedFormGroup;
	@Output() hospitalInfo: EventEmitter<any> = new EventEmitter();

	constructor() {}

	ngOnInit() {
		this.initializeHospitalSearchForm();
	}

	initializeHospitalSearchForm() {
		this.hospitalSearchForm = new UntypedFormGroup({
			ccn: new UntypedFormControl('', [Validators.required, Validators.pattern('^(\\d{6})$')]),
			key: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
		});
	}

	getHospitalInfo() {
		this.hospitalSearchForm.value.key = this.hospitalSearchForm.value.key.includes('AHCAH-')
			? this.hospitalSearchForm.value.key
			: 'AHCAH-' + this.hospitalSearchForm.value.key;
		this.hospitalInfo.emit(this.hospitalSearchForm.value);
	}
}
