import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HCMSLoaderControlService } from '@qnp/qnp-common';
import { HospitalAtHomeService } from '../../../../services/hospital-at-home.service';
@Component({
	selector: 'app-measure-collection-submission',
	templateUrl: './measure-collection-submission.component.html',
	styleUrls: ['./measure-collection-submission.component.css'],
})
export class MeasureCollectionSubmissionComponent implements OnInit {
	isRequestApproved: boolean = false;
	hospitalInfo: any;
	showErrorInfo: boolean;
	errorInfo: string;
	searchItems: any;
	constructor(
		private hosptalSvc: HospitalAtHomeService,
		private loaderControlservice: HCMSLoaderControlService,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {}

	getHospitalInfo(searchPayload) {
		this.isRequestApproved = false;
		this.changeDetector.detectChanges();
		this.loaderControlservice.setLoaderStatus(true);
		this.showErrorInfo = false;
		this.searchItems = searchPayload;
		this.hosptalSvc.getHospitalInfo(searchPayload).subscribe(
			res => {
				this.hospitalInfo = res[0];
				this.isRequestApproved = true;
				this.loaderControlservice.setLoaderStatus(false);
			},
			err => {
				this.showErrorInfo = true;
				this.loaderControlservice.setLoaderStatus(false);
				this.errorInfo = err?.error?.message ? err?.error?.message : err.error.errorMessages[0];
			}
		);
	}
}
