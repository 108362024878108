import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG, IAppEnvConfig } from '@qnp/qnp-common';

@Component({
	selector: 'app-redirect',
	templateUrl: './redirect.component.html',
	styleUrls: ['./redirect.component.css'],
})
export class RedirectComponent implements OnInit {
	public appRedirectURL: string = null;
	public applicationName: string = null;
	public applicationPageName: string = null;

	constructor(private route: ActivatedRoute, @Inject(APP_CONFIG) private config: IAppEnvConfig) {
		// The route is activated when the user is clicking on a link
		// from the HCMS Login (custom Okta Sign In Widget) that will
		// have the route "#/redirect?OKTA_REDIRECT_PARAM_NAME=<appPageName>"
		// We will obtain the HTTP parameter from the URL to determine the
		// application page the user is attempting to access. The code uses
		// a series of if conditions to determine the
		// (1) The Name of the application (i.e. HARP)
		// (2) The name of the application page (i.e. HARP Recovery)
		// (3) The URL of the application page to access (i.e. https://sbx.harp.qualitynet.org/login/account-recovery)
		this.route.queryParams.subscribe(params => {
			this.applicationPageName = params[this.config.OKTA_REDIRECT_PARAM_NAME];

			if (this.config.HARP_RECOVERY_APP_NAME === this.applicationPageName) {
				this.applicationName = this.config.HARP_APPLICATION_NAME;
				this.appRedirectURL = this.config.HARP_BASE_URL + '/' + this.config.HARP_RECOVERY_ENDPOINT;
			} else if (this.config.HARP_SIGNUP_APP_NAME === this.applicationPageName) {
				this.applicationName = this.config.HARP_APPLICATION_NAME;
				this.appRedirectURL = this.config.HARP_BASE_URL + '/' + this.config.HARP_SIGNUP_ENDPOINT;
			}
		});
	}

	ngOnInit() {}

	/**
	 * Navigates to the Application Redirect URL determined by the link the user
	 * accesses from the MFT Login UI (custom Okta Sign In Widget)
	 */
	navigateToUrl() {
		window.location.href = this.appRedirectURL;
	}
}
