import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
	UntypedFormControl,
} from '@angular/forms';
import {
	appTierTypes,
	IFilterSelection,
	invalidFileErrorMessages,
	numericQuestionValidators,
} from '../../../hospital-at-home';
import { isEmpty, cloneDeep } from 'lodash';
import { HospitalAtHomeService } from '../../../../../services/hospital-at-home.service';
import { Tier1SubmissionComponent } from './tier1-submission/tier1-submission.component';
import { Tier2SubmissionComponent } from './tier2-submission/tier2-submission.component';
import { SubmissionHistoryComponent } from '../submission-history/submission-history.component';
import { tap } from 'rxjs/operators';
import { FormService } from '../../../../../services/form.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { HCMSLoaderControlService } from '@qnp/qnp-common';

@Component({
	selector: 'app-application-tier',
	templateUrl: './application-tier.component.html',
	styleUrls: ['./application-tier.component.css'],
})
export class ApplicationTierComponent implements OnInit {
	appTierForm: UntypedFormGroup;
	isCaptchaValid = false;
	reCaptchaToken: string;
	isTier1: boolean;
	@Input() issueType: string;
	@Input() searchItems: any;
	@Input() submissionHistoryTab: any;
	@Input() submissionHistoryComponent: SubmissionHistoryComponent;
	showErrorBanner: boolean = false;
	@ViewChild(Tier1SubmissionComponent) public tier1Component: Tier1SubmissionComponent;
	@ViewChild(Tier2SubmissionComponent) public tier2Component: Tier2SubmissionComponent;
	@ViewChild('recaptcha') public recaptchaElem: RecaptchaComponent;
	fileInvalidMessages: any[];
	showFileInvalid: boolean;
	formControlkeys: string[] = [
		'q1a',
		'q1b',
		'q1c',
		'q2a',
		'q2b',
		'q2c',
		'q3a',
		'q3b',
		'q3c',
		'q4',
		'q5',
	];
	filterType: any;
	isFilterSelected = false;
	selectedTier: Tier1SubmissionComponent | Tier2SubmissionComponent;

	constructor(
		public formBuilder: UntypedFormBuilder,
		private hosptalSvc: HospitalAtHomeService,
		private formSvc: FormService,
		private loaderControlservice: HCMSLoaderControlService
	) {}

	ngOnInit() {
		this.isTier1 = this.issueType === appTierTypes.tier1;
		this.initializeAppTierForm();
		this.listenForFormChanges();
		this.registerFilterSelectionSubscription();
		if (this.formSvc.checkItemExists('ahcah-measures-form')) {
			this.appTierForm.controls['q5'].reset(); //  to reset file in the cache
			const filterType = this.appTierForm.controls?.filterQuestion?.value;
			if (!isEmpty(filterType)) {
				const filterValue: IFilterSelection = {
					filterSelected: true,
					filterType,
				};
				this.hosptalSvc.setHospitalMeasureSelection(filterValue);
			}
			this.updateFormValidity();
		}
	}

	updateFormValidity() {
		this.appTierForm.markAllAsTouched();
		this.appTierForm.updateValueAndValidity();
	}

	initializeAppTierForm() {
		this.appTierForm = this.getTierSubmissionForm();
		this.formSvc.syncWithLocalStorage(this.appTierForm, 'ahcah-measures-form', null, null, false);
	}

	getTierSubmissionForm() {
		return new UntypedFormGroup(
			{
				measurePeriod: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
				filterQuestion: new UntypedFormControl('', Validators.required),
				q1a: new UntypedFormControl('', [...numericQuestionValidators]),
				q1b: new UntypedFormControl('', [...numericQuestionValidators]),
				q1c: new UntypedFormControl('', [...numericQuestionValidators]),
				q2a: new UntypedFormControl('', [...numericQuestionValidators]),
				q2b: new UntypedFormControl('', [...numericQuestionValidators]),
				q2c: new UntypedFormControl('', [...numericQuestionValidators]),
				q3a: new UntypedFormControl('', [...numericQuestionValidators]),
				q3b: new UntypedFormControl('', [...numericQuestionValidators]),
				q3c: new UntypedFormControl('', [...numericQuestionValidators]),
				q4: new UntypedFormControl('', [Validators.required]),
				q5: new UntypedFormControl('', [Validators.required]),
				submitterName: new UntypedFormControl('', [
					Validators.maxLength(255),
					Validators.pattern(`^[a-zA-Z0-9\\-'/.\\s]*$`),
				]),
				submitterEmail: new UntypedFormControl('', [
					Validators.maxLength(255),
					Validators.pattern(`[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$`),
				]),
			},
			{ updateOn: 'blur' }
		);
	}

	listenForFormChanges() {
		this.appTierForm.valueChanges
			.pipe(
				tap(({ q1a, q1b, q1c }) => {
					if (this.filterType === 'Yes') {
						if (q1a === '0' && q1b === '0' && q1c === '0') {
							this.appTierForm.get('q5').setValidators([]);
							this.appTierForm.get('q5').updateValueAndValidity({ emitEvent: false });
						} else {
							this.appTierForm.get('q5').setValidators([Validators.required]);
							this.appTierForm.get('q5').updateValueAndValidity({ emitEvent: false });
						}
					}
				})
			)
			.subscribe();
	}

	registerFilterSelectionSubscription() {
		this.hosptalSvc.getHospitalMeasureSelection().subscribe(({ filterSelected, filterType }) => {
			this.filterType = filterType;
			this.isFilterSelected = filterSelected;
			if (filterSelected && filterType === 'Yes') {
				this.formControlkeys.map(ctrlKey => {
					if (ctrlKey === 'q4' || ctrlKey === 'q5') {
						this.appTierForm.get(ctrlKey).setValidators([Validators.required]);
					} else {
						this.appTierForm.get(ctrlKey).setValidators([...numericQuestionValidators]);
					}
					this.appTierForm.get(ctrlKey).updateValueAndValidity({ emitEvent: false });
				});
			} else {
				this.formControlkeys.map(ctrlKey => {
					this.appTierForm.get(ctrlKey).setValidators([]);
					this.appTierForm.get(ctrlKey).updateValueAndValidity({ emitEvent: false });
				});
			}
			if (this.formSvc.checkItemExists('ahcah-measures-form')) {
				this.updateFormValidity();
			}
		});
	}

	resolved(captchaResponse: string) {
		this.isCaptchaValid = !isEmpty(captchaResponse);
		this.reCaptchaToken = captchaResponse;
	}

	submitHospitalMeasures() {
		this.showFileInvalid = false;
		this.showErrorBanner = false;
		const payload = this.buildPostRequestPayload();
		if (isEmpty(payload)) {
			this.showFileInvalid = true;
			return;
		}
		this.loaderControlservice.setLoaderStatus(true);
		this.hosptalSvc.submitHopitalAtHomeMeasure(payload).subscribe(
			res => {
				this.showErrorBanner = false;
				this.loaderControlservice.setLoaderStatus(false);
				this.submissionHistoryComponent.submissionSuccess = true;
				this.submissionHistoryTab.click();
				window.scrollTo(0, 750);
				this.resetFormData();
			},
			err => {
				this.submissionHistoryComponent.submissionSuccess = false;
				this.loaderControlservice.setLoaderStatus(false);
				this.showErrorBanner = true;
			}
		);
	}

	resetFormData() {
		this.recaptchaElem.reset();
		this.formSvc.removeFromLocalStorage('ahcah-measures-form');
		this.initializeAppTierForm();
		this.listenForFormChanges();
		const filter: IFilterSelection = {
			filterSelected: false,
		};
		this.hosptalSvc.setHospitalMeasureSelection(filter);
		this.hosptalSvc.refreshSubmissionHistory.next();
		this.fileInvalidMessages = [];
		this.showFileInvalid = false;
		this.selectedTier.csvFileData = undefined;
		this.selectedTier.csvString = undefined;
	}

	buildPostRequestPayload() {
		this.fileInvalidMessages = [];
		this.selectedTier = this.isTier1 ? this.tier1Component : this.tier2Component;
		const formValue = this.appTierForm.value;
		const dateRange = this.selectedTier.DateArr.reduce((acc, item) => {
			if (item.key === Number(formValue.measurePeriod)) {
				acc.push(item.from, item.to);
			}
			return acc;
		}, []);
		if (this.selectedTier.csvFileData) {
			this.fileInvalidMessages = cloneDeep(this.selectedTier.fileUploadErrors);
			this.fileInvalidMessages = this.removeDefaultLibraryChecks(this.fileInvalidMessages);
			let q1aRowCountCheck = 0;
			let q1bRowCountCheck = 0;
			let q1cRowCountCheck = 0;
			const date_regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1[0-9]|2[0-9]|3[01])\/\d{4}$/;
			this.selectedTier.csvFileData.forEach((element, index) => {
				const validMedicare = !isEmpty(element.medicare) && element.medicare !== '0';
				const validBeneficiary = !isEmpty(element.beneficiary) && element.beneficiary !== '0';
				if (validMedicare) ++q1aRowCountCheck;
				if (validBeneficiary) ++q1bRowCountCheck;
				if (validMedicare && validBeneficiary) ++q1cRowCountCheck;
				if (element.beneficiary.includes('E+') || element.medicare.includes('E+')) {
					this.fileInvalidMessages.push(
						'CSV file contains invalid MBI and/or Medicaid ID. Please verify fields are formatted as text and not a number.'
					);
				}
				if (validBeneficiary || validMedicare) {
					if (isEmpty(element.admissionDate)) {
						this.fileInvalidMessages.push(
							`CSV file contains empty Admission Date in row ${index + 2}.`
						);
					}
					if (!isEmpty(element.admissionDate) && !date_regex.test(element.admissionDate)) {
						this.fileInvalidMessages.push(
							`CSV file contains invalid  Admission Date in row ${index + 2}.`
						);
					}
				}
				if (
					(element.medicare === '0' || isEmpty(element.medicare)) &&
					(element.beneficiary === '0' || isEmpty(element.beneficiary)) &&
					!isEmpty(element.admissionDate) &&
					!date_regex.test(element.admissionDate)
				) {
					this.fileInvalidMessages.push(
						`CSV file contains invalid  Admission Date in row ${index + 2}.`
					);
				}
			});
			if (Number(formValue.q1a) !== q1aRowCountCheck)
				this.fileInvalidMessages.push(
					`${invalidFileErrorMessages.invalidQ1aRowCount} File contains ${q1aRowCountCheck} row(s) with MBI.`
				);
			if (Number(formValue.q1b) !== q1bRowCountCheck)
				this.fileInvalidMessages.push(
					`${invalidFileErrorMessages.invalidQ1bRowCount} File contains ${q1bRowCountCheck} row(s) with Medicaid ID.`
				);
			if (Number(formValue.q1c) !== q1cRowCountCheck)
				this.fileInvalidMessages.push(
					`${invalidFileErrorMessages.invalidQ1cRowCount} File contains ${q1cRowCountCheck} row(s) with Dual-Eligibles.`
				);

			if (!isEmpty(this.fileInvalidMessages)) {
				this.fileInvalidMessages = [...new Set(this.fileInvalidMessages)];
				return {};
			}
		}
		const isFilterValid = this.filterType === 'Yes';
		const postPayload: any = {
			issueType: this.isTier1 ? 'tierOne' : 'tierTwo',
			recaptchaToken: this.reCaptchaToken,
			ccn: this.searchItems.ccn,
			key: this.searchItems.key,
			measuresFromDate: dateRange[0],
			measuresToDate: dateRange[1],
			commonText: {
				q1a: isFilterValid ? Number(formValue.q1a) : 0,
				q1b: isFilterValid ? Number(formValue.q1b) : 0,
				q1c: isFilterValid ? Number(formValue.q1c) : 0,
				q2a: isFilterValid ? Number(formValue.q2a) : 0,
				q2b: isFilterValid ? Number(formValue.q2b) : 0,
				q2c: isFilterValid ? Number(formValue.q2c) : 0,
				q3a: isFilterValid ? Number(formValue.q3a) : 0,
				q3b: isFilterValid ? Number(formValue.q3b) : 0,
				q3c: isFilterValid ? Number(formValue.q3c) : 0,
				...(isFilterValid &&
					!isEmpty(this.selectedTier.csvString) && { q5: this.selectedTier.csvString }),
			},
			dropdowns: {
				q4: isFilterValid ? this.appTierForm.value.q4 : false,
			},
		};
		if (!isEmpty(formValue.submitterName)) postPayload.submitterName = formValue.submitterName;
		if (!isEmpty(formValue.submitterEmail)) postPayload.submitterEmail = formValue.submitterEmail;
		return postPayload;
	}

	private removeDefaultLibraryChecks(fileUploadErrors) {
		return fileUploadErrors?.filter(
			errorMessage => !errorMessage?.toLowerCase()?.includes('too many fields')
		);
	}

	getCommontextFileds({ q1a, q1b, q1c, q2a, q2b, q2c, q3a, q3b, q3c, q5 }) {
		return { q1a, q1b, q1c, q2a, q2b, q2c, q3a, q3b, q3c, q5 };
	}
}
