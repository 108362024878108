import { UntypedFormGroup, Validators } from '@angular/forms';
import {
	NoLeadingTrailingWhitespaceValidator,
	QNPFormConfig,
	alphanumericValidator,
	createField,
	exactLengthValidator,
	numberOnlyOrNumberWithCustomStringValidator,
	emailValidator,
	rangeValidator,
	QNPFormFieldType,
	futureDateValidator,
	dateRangeValidator,
} from '@qnp/qnp-common';

export const alzheimersFormGroups: { [key: string]: UntypedFormGroup } = {
	studyIdentifier: new UntypedFormGroup({}),
	contactInfo: new UntypedFormGroup({}),
	diagnosis: new UntypedFormGroup({}),
	clinicalDiagnosis: new UntypedFormGroup({}),
	cognitiveTests: new UntypedFormGroup({}),
	functionalTests: new UntypedFormGroup({}),
	combinedTestResults: new UntypedFormGroup({}),
	otherInfo: new UntypedFormGroup({}),
};

export const stateOptions = [
	{ name: 'American Samoa', value: 'AS' },
	{ name: 'Commonwealth of Northern Mariana Islands', value: 'MP' },
	{ name: 'Guam', value: 'GU' },
	{ name: 'Commonwealth of Puerto Rico', value: 'PR' },
	{ name: 'U.S. Virgin Islands', value: 'VI' },
	{ name: 'Alabama', value: 'AL' },
	{ name: 'Alaska', value: 'AK' },
	{ name: 'Arizona', value: 'AZ' },
	{ name: 'Arkansas', value: 'AR' },
	{ name: 'California', value: 'CA' },
	{ name: 'Colorado', value: 'CO' },
	{ name: 'Connecticut', value: 'CT' },
	{ name: 'Delaware', value: 'DE' },
	{ name: 'Dist of Columbia', value: 'DC' },
	{ name: 'Florida', value: 'FL' },
	{ name: 'Georgia', value: 'GA' },
	{ name: 'Hawaii', value: 'HI' },
	{ name: 'Idaho', value: 'ID' },
	{ name: 'Illinois', value: 'IL' },
	{ name: 'Indiana', value: 'IN' },
	{ name: 'Iowa', value: 'IA' },
	{ name: 'Kansas', value: 'KS' },
	{ name: 'Kentucky', value: 'KY' },
	{ name: 'Louisiana', value: 'LA' },
	{ name: 'Maine', value: 'ME' },
	{ name: 'Maryland', value: 'MD' },
	{ name: 'Massachusetts', value: 'MA' },
	{ name: 'Michigan', value: 'MI' },
	{ name: 'Minnesota', value: 'MN' },
	{ name: 'Mississippi', value: 'MS' },
	{ name: 'Missouri', value: 'MO' },
	{ name: 'Montana', value: 'MT' },
	{ name: 'Nebraska', value: 'NE' },
	{ name: 'Nevada', value: 'NV' },
	{ name: 'New Hampshire', value: 'NH' },
	{ name: 'New Jersey', value: 'NJ' },
	{ name: 'New Mexico', value: 'NM' },
	{ name: 'New York', value: 'NY' },
	{ name: 'North Carolina', value: 'NC' },
	{ name: 'North Dakota', value: 'ND' },
	{ name: 'Ohio', value: 'OH' },
	{ name: 'Oklahoma', value: 'OK' },
	{ name: 'Oregon', value: 'OR' },
	{ name: 'Pennsylvania', value: 'PA' },
	{ name: 'Rhode Island', value: 'RI' },
	{ name: 'South Carolina', value: 'SC' },
	{ name: 'South Dakota', value: 'SD' },
	{ name: 'Tennessee', value: 'TN' },
	{ name: 'Texas', value: 'TX' },
	{ name: 'Utah', value: 'UT' },
	{ name: 'Vermont', value: 'VT' },
	{ name: 'Virginia', value: 'VA' },
	{ name: 'Washington', value: 'WA' },
	{ name: 'West Virginia', value: 'WV' },
	{ name: 'Wisconsin', value: 'WI' },
	{ name: 'Wyoming', value: 'WY' },
];

export const cagAlzheimersFormConfigurations: any[] = [
	{
		name: 'studyIdentifier',
		formFields: 'studyIdentifierFormFields',
		isHorizontalForm: true,
		isDynamic: true,
	},
	{
		name: 'contactInfo',
		formFields: 'contactInfoFormFields',
		isHorizontalForm: false,
		isDynamic: true,
	},
	{
		name: 'diagnosis',
		formFields: 'diagnosisFormFields',
		isHorizontalForm: false,
		isDynamic: true,
	},
	{
		name: 'clinicalDiagnosis',
		formFields: 'clinicalDiagnosisFormFields',
		isHorizontalForm: false,
		isDynamic: true,
	},
	{
		name: 'cognitiveTests',
		formFields: 'cognitiveTestsFormFields',
		isHorizontalForm: false,
		isDynamic: true,
	},
	{
		name: 'functionalTests',
		formFields: 'functionalTestsFormFields',
		isHorizontalForm: false,
		isDynamic: true,
	},
	{
		name: 'combinedTestResults',
		formFields: 'combinedTestResultsFormFields',
		isHorizontalForm: true,
		isDynamic: true,
	},
	{
		name: 'otherInfo',
		formFields: 'otherInfoFormFields',
		isHorizontalForm: false,
		isDynamic: true,
	},
];

export enum cagAlzheimersFieldKeys {
	CCN = 'ccn',
	NPI = 'npi',
	DateOfVisit = 'dateOfVisit',
	MBI = 'mbi',
	NCT = 'nctNumber',
	SubmitterAddressList = 'submitterAddressList',
	SubmitterEmail = 'submitterEmail',
	SubmitterAddress = 'submitterAddress',
	SubmitterStateCode = 'submitterStateCode',
	SubmitterZipCode = 'submitterZipCode',
	SubmitterCity = 'submitterCity',
	ClinicalDiagnosisType = 'clinicalDiagnosisType',
	ClinicalDiagnosisDate = 'clinicalDiagnosisDate',
	AmyloidPETScan = 'amyloidPETScan',
	AmyloidPETScanDate = 'amyloidPETScanDate',
	CSFTestResult = 'csfTestResult',
	CSFTestResultDate = 'csfTestResultDate',
	OtherAmyloidTest = 'otherAmyloidTest',
	OtherAmyloidTestDate = 'otherAmyloidTestDate',
	OtherAmyloidTestType = 'otherAmyloidTestType',
	OtherInformation = 'otherInformation',
	CombinedTestResultsFlag = 'combinedTestResultsFlag',
	CdrTestPerformed = 'cdrTestPerformed',
	Memory = 'memory',
	Orientation = 'orientation',
	JudgementAndProblemSolving = 'judgementAndProblemSolving',
	CommunityAffairs = 'communityAffairs',
	HomeAndHobbies = 'homeAndHobbies',
	PersonalCare = 'personalCare',
	SumOfBoxes = 'sumOfBoxes',
	Global = 'global',
	CdrDate = 'cdrDate',
	MocaScore = 'mocaScore',
	MocaScoreDate = 'mocaScoreDate',
	OtherCognitiveTest = 'otherCognitiveTest',
	OtherCognitiveTestScore = 'otherCognitiveTestScore',
	OtherCognitiveTestDate = 'otherCognitiveTestDate',
	FaqScore = 'faqScore',
	FaqScoreDate = 'faqScoreDate',
	OtherFunctionalTest = 'otherFunctionalTest',
	OtherFunctionalTestScore = 'otherFunctionalTestScore',
	OtherFunctionalTestDate = 'otherFunctionalTestDate',
	Anticoagulation = 'anticoagulation',
	Antiplatelets = 'antiplatelets',
	MonoclonalAntibodyUsed = 'monoclonalAntibodyUsed',
	NameOfMonoclonalAntibodyUsed = 'nameOfmonoclonalAntibodyUsed',
	AriaEEvidence = 'ariaEEvidence',
	AriaEEvidenceDate = 'ariaEEvidenceDate',
	AriaHEvidence = 'ariaHEvidence',
	AriaHEvidenceDate = 'ariaHEvidenceDate',
}

export const generalInfoFields: QNPFormConfig = [
	{
		heading: 'General Information',
		fields: [
			createField(
				cagAlzheimersFieldKeys.NPI,
				QNPFormFieldType.Text,
				'National Provider Identifier (NPI)',
				true,
				[
					Validators.required,
					exactLengthValidator([10]),
					numberOnlyOrNumberWithCustomStringValidator(),
					NoLeadingTrailingWhitespaceValidator(),
				],
				{
					helpText:
						'For more information refer to the NPI Registry: https://npiregistry.cms.hhs.gov/search.',
				}
			),
			createField(
				cagAlzheimersFieldKeys.MBI,
				QNPFormFieldType.Text,
				'Medicare Beneficiary ID (MBI)',
				true,
				[
					Validators.required,
					exactLengthValidator([11]),
					alphanumericValidator(),
					NoLeadingTrailingWhitespaceValidator(),
				]
			),
		],
	},
];

export const studyIdentifierFields: QNPFormConfig = [
	{
		heading: 'CED Study Identifier',
		fields: [
			createField(
				cagAlzheimersFieldKeys.NCT,
				QNPFormFieldType.DropDown,
				'NCT Number',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'NCT06058234 (formerly 99999999)', value: 'NCT06058234 (formerly 99999999)' },
					],
					readOnly: true,
					defaultValue: 'NCT06058234 (formerly 99999999)',
					toolTipText: 'National Clinical Trial',
				}
			),
		],
	},
];
export const contactInfoFields: QNPFormConfig = [
	{
		heading: 'Contact Information',
		fields: [
			createField(
				cagAlzheimersFieldKeys.SubmitterEmail,
				QNPFormFieldType.Text,
				'Submitter Email Address',
				true,
				[Validators.required, NoLeadingTrailingWhitespaceValidator(), emailValidator()],
				{
					helpText:
						'Your email address will be kept confidential and will not be publically shared.',
				}
			),
			createField(
				cagAlzheimersFieldKeys.SubmitterAddress,
				QNPFormFieldType.Text,
				'Street Address',
				false,
				[NoLeadingTrailingWhitespaceValidator()]
			),
			createField(
				cagAlzheimersFieldKeys.SubmitterStateCode,
				QNPFormFieldType.DropDown,
				'State',
				true,
				[Validators.required],
				{
					options: stateOptions,
				}
			),
			createField(cagAlzheimersFieldKeys.SubmitterCity, QNPFormFieldType.Text, 'City', true, [
				Validators.required,
				NoLeadingTrailingWhitespaceValidator(),
			]),
			createField(
				cagAlzheimersFieldKeys.SubmitterZipCode,
				QNPFormFieldType.Text,
				'Zip Code',
				true,
				[Validators.required, NoLeadingTrailingWhitespaceValidator()]
			),
		],
	},
];

export const diagnosisFields: QNPFormConfig = [
	{
		heading: 'Diagnosis',
		formId: 'diagnosis-form',
		fields: [
			createField(
				cagAlzheimersFieldKeys.ClinicalDiagnosisType,
				QNPFormFieldType.DropDown,
				'Clinical Diagnosis',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'MCI Due to AD', value: 'MCI Due to AD' },
						{ name: 'Mild AD Dementia', value: 'Mild AD Dementia' },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.ClinicalDiagnosisDate,
				QNPFormFieldType.Date,
				'Date of Clinical Diagnosis',
				true,
				[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)]
			),
		],
	},
];

export const clinicalDiagnosisFields: QNPFormConfig = [
	{
		heading: 'One of the below tests to confirm amyloid pathology is required',
		formId: 'clinical-diagnosis-form',
		fields: [
			createField(
				cagAlzheimersFieldKeys.AmyloidPETScan,
				QNPFormFieldType.DropDown,
				'Amyloid PET Scan',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'Positive', value: 'Positive' },
						{ name: 'Negative', value: 'Negative' },
						{ name: 'Not Performed', value: 'Not Performed' },
					],
					defaultValue: 'Not Performed',
					toolTipText: 'Positron Emission Tomography (PET)',
				}
			),
			createField(
				cagAlzheimersFieldKeys.AmyloidPETScanDate,
				QNPFormFieldType.Date,
				'Date of Amyloid PET Scan',
				true,
				[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)],
				{ toolTipText: 'Positron Emission Tomography (PET)' }
			),
			createField(
				cagAlzheimersFieldKeys.CSFTestResult,
				QNPFormFieldType.DropDown,
				'CSF Test',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'Positive', value: 'Positive' },
						{ name: 'Negative', value: 'Negative' },
						{ name: 'Not Performed', value: 'Not Performed' },
					],
					defaultValue: 'Not Performed',
					toolTipText: 'Cerebralspinal Fluid (CSF)',
				}
			),
			createField(
				cagAlzheimersFieldKeys.CSFTestResultDate,
				QNPFormFieldType.Date,
				'Date of CSF Test',
				true,
				[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)],
				{ toolTipText: 'Cerebralspinal Fluid (CSF)' }
			),
			createField(
				cagAlzheimersFieldKeys.OtherAmyloidTest,
				QNPFormFieldType.DropDown,
				'Other Amyloid Test',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'Positive', value: 'Positive' },
						{ name: 'Negative', value: 'Negative' },
						{ name: 'Not Performed', value: 'Not Performed' },
					],
					defaultValue: 'Not Performed',
				}
			),
			createField(
				cagAlzheimersFieldKeys.OtherAmyloidTestDate,
				QNPFormFieldType.Date,
				'Date of Other Amyloid Test',
				true,
				[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)]
			),
			createField(
				cagAlzheimersFieldKeys.OtherAmyloidTestType,
				QNPFormFieldType.Text,
				'Type of Other Amyloid Test',
				true,
				[Validators.required, NoLeadingTrailingWhitespaceValidator(), Validators.maxLength(255)]
			),
			createField(
				cagAlzheimersFieldKeys.OtherInformation,
				QNPFormFieldType.Text,
				'Other Information',
				false,
				[NoLeadingTrailingWhitespaceValidator(), Validators.maxLength(100)]
			),
		],
	},
];

export const combinedTestResultsFields: QNPFormConfig = [
	{
		heading: 'The test below is optional',
		fields: [
			createField(
				cagAlzheimersFieldKeys.CdrTestPerformed,
				QNPFormFieldType.RadioButton,
				'Did you perform a CDR?',
				false,
				[],
				{
					options: [
						{ name: 'Yes', value: 'Yes' },
						{ name: 'No', value: 'No' },
					],
					toolTipText: 'Clinical Dementia Rating',
				}
			),
			createField(
				cagAlzheimersFieldKeys.Memory,
				QNPFormFieldType.DropDown,
				'Memory',
				true,
				[Validators.required],
				{
					options: [
						{ name: '0', value: 0 },
						{ name: '0.5', value: 0.5 },
						{ name: '1', value: 1 },
						{ name: '2', value: 2 },
						{ name: '3', value: 3 },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.Orientation,
				QNPFormFieldType.DropDown,
				'Orientation',
				true,
				[Validators.required],
				{
					options: [
						{ name: '0', value: 0 },
						{ name: '0.5', value: 0.5 },
						{ name: '1', value: 1 },
						{ name: '2', value: 2 },
						{ name: '3', value: 3 },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.JudgementAndProblemSolving,
				QNPFormFieldType.DropDown,
				'Judgement and Problem Solving',
				true,
				[Validators.required],
				{
					options: [
						{ name: '0', value: 0 },
						{ name: '0.5', value: 0.5 },
						{ name: '1', value: 1 },
						{ name: '2', value: 2 },
						{ name: '3', value: 3 },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.CommunityAffairs,
				QNPFormFieldType.DropDown,
				'Community Affairs',
				true,
				[Validators.required],
				{
					options: [
						{ name: '0', value: 0 },
						{ name: '0.5', value: 0.5 },
						{ name: '1', value: 1 },
						{ name: '2', value: 2 },
						{ name: '3', value: 3 },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.HomeAndHobbies,
				QNPFormFieldType.DropDown,
				'Home and Hobbies',
				true,
				[Validators.required],
				{
					options: [
						{ name: '0', value: 0 },
						{ name: '0.5', value: 0.5 },
						{ name: '1', value: 1 },
						{ name: '2', value: 2 },
						{ name: '3', value: 3 },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.PersonalCare,
				QNPFormFieldType.DropDown,
				'Personal Care',
				true,
				[Validators.required],
				{
					options: [
						{ name: '0', value: 0 },
						{ name: '0.5', value: 0.5 },
						{ name: '1', value: 1 },
						{ name: '2', value: 2 },
						{ name: '3', value: 3 },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.SumOfBoxes,
				QNPFormFieldType.Text,
				'Sum of Boxes',
				false,
				[NoLeadingTrailingWhitespaceValidator()],
				{ readOnly: true, defaultValue: 0 }
			),
			createField(
				cagAlzheimersFieldKeys.Global,
				QNPFormFieldType.DropDown,
				'Global',
				true,
				[Validators.required],
				{
					options: [
						{ name: '0', value: 0 },
						{ name: '0.5', value: 0.5 },
						{ name: '1', value: 1 },
						{ name: '2', value: 2 },
						{ name: '3', value: 3 },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.CdrDate,
				QNPFormFieldType.Date,
				'Date for CDR',
				true,
				[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)],
				{ toolTipText: 'Clinical Dementia Rating' }
			),
		],
	},
];

export const cognitiveTestsFields: QNPFormConfig = [
	{
		heading: 'At least one of the cognitive tests below is required',
		formId: 'cognitive-tests',
		fields: [
			createField(
				cagAlzheimersFieldKeys.MocaScore,
				QNPFormFieldType.Text,
				'MoCA Score',
				false,
				[
					NoLeadingTrailingWhitespaceValidator(),
					numberOnlyOrNumberWithCustomStringValidator(),
					rangeValidator(0, 30),
				],
				{ toolTipText: 'Montreal Cognitive Assessment (MoCA)' }
			),
			createField(
				cagAlzheimersFieldKeys.MocaScoreDate,
				QNPFormFieldType.Date,
				'Date of MoCA',
				false,
				[futureDateValidator, dateRangeValidator('01/01/1970', null)],
				{
					toolTipText: 'Montreal Cognitive Assessment (MoCA)',
				}
			),
			createField(
				cagAlzheimersFieldKeys.OtherCognitiveTest,
				QNPFormFieldType.Text,
				'Other Cognitive Test',
				false,
				[
					NoLeadingTrailingWhitespaceValidator(),
					Validators.maxLength(100),
					alphanumericValidator(true),
				]
			),
		],
	},
];
export const functionalTestsFields: QNPFormConfig = [
	{
		heading: 'At least one of the functional tests below is required',
		formId: 'functional-tests',
		fields: [
			createField(
				cagAlzheimersFieldKeys.FaqScore,
				QNPFormFieldType.Text,
				'FAQ Score',
				false,
				[
					NoLeadingTrailingWhitespaceValidator(),
					numberOnlyOrNumberWithCustomStringValidator(),
					rangeValidator(0, 30),
				],
				{
					toolTipText: 'Functional Activities Questionnaire (FAQ)',
				}
			),
			createField(
				cagAlzheimersFieldKeys.FaqScoreDate,
				QNPFormFieldType.Date,
				'Date of FAQ',
				false,
				[futureDateValidator, dateRangeValidator('01/01/1970', null)],
				{
					toolTipText: 'Functional Activities Questionnaire (FAQ)',
				}
			),
			createField(
				cagAlzheimersFieldKeys.OtherFunctionalTest,
				QNPFormFieldType.Text,
				'Other Functional Test',
				false,
				[
					NoLeadingTrailingWhitespaceValidator(),
					Validators.maxLength(100),
					alphanumericValidator(true),
				]
			),
		],
	},
];

export const otherInfoFields: QNPFormConfig = [
	{
		heading: 'Additional Required Information',

		fields: [
			createField(
				cagAlzheimersFieldKeys.Anticoagulation,
				QNPFormFieldType.RadioButton,
				'Is the patient on anticoagulation?',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'Yes', value: 'Yes' },
						{ name: 'No', value: 'No' },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.Antiplatelets,
				QNPFormFieldType.RadioButton,
				'Is the patient on antiplatelets?',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'Yes', value: 'Yes' },
						{ name: 'No', value: 'No' },
					],
				}
			),
			createField(
				cagAlzheimersFieldKeys.MonoclonalAntibodyUsed,
				QNPFormFieldType.DropDown,
				'Monoclonal Antibody Used:',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'Lecanemab', value: 'Lecanemab' },
						{ name: 'Donanemab', value: 'Donanemab' },
					],
				}
			),

			createField(
				cagAlzheimersFieldKeys.AriaEEvidence,
				QNPFormFieldType.RadioButton,
				'Is there evidence of significant ARIA-E?',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'Yes', value: 'Yes' },
						{ name: 'No', value: 'No' },
					],
					toolTipText: 'Amyloid Related Imaging Abnormalities-Edema (ARIA-E)',
				}
			),
			createField(
				cagAlzheimersFieldKeys.AriaEEvidenceDate,
				QNPFormFieldType.Date,
				'Date of ARIA-E Test',
				true,
				[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)],
				{ toolTipText: 'Amyloid Related Imaging Abnormalities-Edema (ARIA-E)' }
			),
			createField(
				cagAlzheimersFieldKeys.AriaHEvidence,
				QNPFormFieldType.RadioButton,
				'Is there evidence of significant ARIA-H?',
				true,
				[Validators.required],
				{
					options: [
						{ name: 'Yes', value: 'Yes' },
						{ name: 'No', value: 'No' },
					],
					toolTipText: 'Amyloid Related Imaging Abnormalities-Hemorrhage (ARIA-H)',
				}
			),
			createField(
				cagAlzheimersFieldKeys.AriaHEvidenceDate,
				QNPFormFieldType.Date,
				'Date of ARIA-H Test',
				true,
				[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)],
				{ toolTipText: 'Amyloid Related Imaging Abnormalities-Hemorrhage (ARIA-H)' }
			),
		],
	},
];
