// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Override Bootstrap */
.nav-pills {
	border-bottom: 1px #ddd solid;
}

.nav-pills .nav-link {
	border-radius: 0;
	background-color: transparent;
	color: #046b99;
	padding-left: 20px;
	padding-right: 20px;
	border: none;
}

.nav-pills .nav-link.active {
	border-bottom: 4px #046b99 solid;
	font-weight: 700;
	color: #212121;
}

/* Waiver icons */
.qnp-waiver-icon {
	width: 24px;
	height: 24px;
	border-radius: 50px;
	text-align: center;
	display: inline-block;
	margin-right: 0.25rem;
}

.qnp-waiver-icon img {
	width: 14px;
}

.qnp-waiver-icon-approved {
	background-color: #2a7a3b;
}
.qnp-waiver-icon-rejected {
	background-color: #cd2026;
}
.qnp-waiver-icon-in-progress {
	background-color: #e4a61b;
}
.qnp-waiver-icon-submitted {
	background-color: #00a6d2;
}
.qnp-waiver-icon-no-action,
.qnp-waiver-icon-duplicate {
	background-color: #aeb0b5;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/hospitals-at-home/measure/measure-collection-submission/measure-collection-submission.component.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;CACC,6BAA6B;AAC9B;;AAEA;CACC,gBAAgB;CAChB,6BAA6B;CAC7B,cAAc;CACd,kBAAkB;CAClB,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,gCAAgC;CAChC,gBAAgB;CAChB,cAAc;AACf;;AAEA,iBAAiB;AACjB;CACC,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,kBAAkB;CAClB,qBAAqB;CACrB,qBAAqB;AACtB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,yBAAyB;AAC1B;AACA;CACC,yBAAyB;AAC1B;AACA;CACC,yBAAyB;AAC1B;AACA;CACC,yBAAyB;AAC1B;AACA;;CAEC,yBAAyB;AAC1B","sourcesContent":["/* Override Bootstrap */\n.nav-pills {\n\tborder-bottom: 1px #ddd solid;\n}\n\n.nav-pills .nav-link {\n\tborder-radius: 0;\n\tbackground-color: transparent;\n\tcolor: #046b99;\n\tpadding-left: 20px;\n\tpadding-right: 20px;\n\tborder: none;\n}\n\n.nav-pills .nav-link.active {\n\tborder-bottom: 4px #046b99 solid;\n\tfont-weight: 700;\n\tcolor: #212121;\n}\n\n/* Waiver icons */\n.qnp-waiver-icon {\n\twidth: 24px;\n\theight: 24px;\n\tborder-radius: 50px;\n\ttext-align: center;\n\tdisplay: inline-block;\n\tmargin-right: 0.25rem;\n}\n\n.qnp-waiver-icon img {\n\twidth: 14px;\n}\n\n.qnp-waiver-icon-approved {\n\tbackground-color: #2a7a3b;\n}\n.qnp-waiver-icon-rejected {\n\tbackground-color: #cd2026;\n}\n.qnp-waiver-icon-in-progress {\n\tbackground-color: #e4a61b;\n}\n.qnp-waiver-icon-submitted {\n\tbackground-color: #00a6d2;\n}\n.qnp-waiver-icon-no-action,\n.qnp-waiver-icon-duplicate {\n\tbackground-color: #aeb0b5;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
