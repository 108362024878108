import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-point-of-contact',
	templateUrl: './point-of-contact.component.html',
	styleUrls: ['./point-of-contact.component.css'],
})
export class PointOfContactComponent implements OnInit {
	@Input() pointOfContactForm: any;

	ngOnInit() {
		this.listenForPointOfContactFormChanges();
		this.updateConfirmEmailValidity();
	}

	private listenForPointOfContactFormChanges() {
		this.pointOfContactForm
			.get('pocEmail')
			.valueChanges.subscribe(() => this.updateConfirmEmailValidity());

		this.pointOfContactForm
			.get('pocConfirmEmail')
			.valueChanges.subscribe(() => this.updateConfirmEmailValidity());
	}

	private updateConfirmEmailValidity() {
		this.pointOfContactForm.controls['pocConfirmEmail'].updateValueAndValidity({
			emitEvent: false,
		});
	}

	omitNumber(event) {
		const key = event.which;
		if (key == 43 || key == 45 || key == 101) return false;
	}
}
