import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { PageService } from './services/page.service';
import { HospitalAtHomeService } from './services/hospital-at-home.service';
import { FormService } from './services/form.service';

import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { RedirectComponent } from './redirect/redirect.component';
import { PageComponent } from './public/page/page.component';
import { FilesComponent } from './public/files/files.component';
import { QnpCommonModule } from './common/qnp-common.module';
import { PublicComponent } from './public/public.component';
import { SearchComponent } from './public/search/search/search.component';
import { NewsComponent } from './public/news/news.component';
import { SignupComponent } from './public/list-serve/signup/listserv-signup.component';
import { ListserveComponent } from './public/list-serve/listserv.component';
import { PublicHeaderComponent } from './public/components/public-header/public-header.component';
import { PublicFooterComponent } from './public/components/public-footer/public-footer.component';
import { ListServSuccessComponent } from './public/list-serve/success/listserv-success.component';

import { HospitalsAtHomeComponent } from './public/hospitals-at-home/waiver/hospitals-at-home.component';
import { Tier2FormComponent } from './public/hospitals-at-home/waiver/tier2-form/tier2-form.component';
import { Tier1FormComponent } from './public/hospitals-at-home/waiver/tier1-form/tier1-form.component';
import { HospitalInformationComponent } from './public/hospitals-at-home/waiver/hospital-information/hospital-information.component';
import { PointOfContactComponent } from './public/hospitals-at-home/waiver/point-of-contact/point-of-contact.component';
import { AttestationComponent } from './public/hospitals-at-home/waiver/attestation/attestation.component';
import { TierTypeComponent } from './public/hospitals-at-home/waiver/tier-type/tier-type.component';
import { HospitalsAtHomeSuccessComponent } from './public/hospitals-at-home/waiver/hospitals-at-home-success/hospitals-at-home-success.component';
import { HospitalSearchComponent } from './public/hospitals-at-home/measure/hospital-search/hospital-search.component';
import { HospitalsAtHomeMeasureComponent } from './public/hospitals-at-home/measure/hospital-at-home-meaure.component';
import { MeasureCollectionSubmissionComponent } from './public/hospitals-at-home/measure/measure-collection-submission/measure-collection-submission.component';
import { SubmissionHistoryComponent } from './public/hospitals-at-home/measure/measure-collection-submission/submission-history/submission-history.component';
import { ApplicationTierComponent } from './public/hospitals-at-home/measure/measure-collection-submission/application-tier/application-tier.component';
import { Tier1SubmissionComponent } from './public/hospitals-at-home/measure/measure-collection-submission/application-tier/tier1-submission/tier1-submission.component';
import { Tier2SubmissionComponent } from './public/hospitals-at-home/measure/measure-collection-submission/application-tier/tier2-submission/tier2-submission.component';
import { CallbackComponent } from './callback/callback.component';
import { PublicErrorComponent } from './public/components/error-component/error-component';
import { WindowService } from './services/window.service';
import { ESSInterceptor, QnpLibCommonModule, ToastrModule } from '@qnp/qnp-common';
import { VaccineAttestationComponent } from './public/vaccine-attestation/vaccine-attestation.component';
import { VaccineAttestationService } from './services/vaccine-attestation.service';
import { FileUploadModule } from 'ng2-file-upload';
import { NursingHomeFormComponent } from './public/vaccine-attestation/nursing-home-form/nursing-home-form.component';
import { AccFormComponent } from './public/vaccine-attestation/acc-form/acc-form.component';
import { VmapSuccesComponent } from './public/vaccine-attestation/vmap-succes/vmap-succes.component';
import { CagAlzheimerComponent } from './public/cag-alzheimer/cag-alzheimer.component';
import { AlzheimersService } from './services/alzheimers.service';
import { CAGLandingSuccessPageComponent } from './public/cag-alzheimer/cag-landing-page/cag-landing-success-page.component';

// need to add 'page not found'

const hospitalsAtHomeComponents = [
	HospitalsAtHomeComponent,
	Tier2FormComponent,
	Tier1FormComponent,
	HospitalInformationComponent,
	PointOfContactComponent,
	AttestationComponent,
	TierTypeComponent,
	HospitalsAtHomeSuccessComponent,
	HospitalsAtHomeMeasureComponent,
	HospitalSearchComponent,
	MeasureCollectionSubmissionComponent,
	SubmissionHistoryComponent,
	ApplicationTierComponent,
	Tier1SubmissionComponent,
	Tier2SubmissionComponent,
];

@NgModule({
	declarations: [
		AppComponent,
		PageComponent,
		TermsconditionsComponent,
		RedirectComponent,
		FilesComponent,
		PublicComponent,
		SearchComponent,
		NewsComponent,
		ListserveComponent,
		SignupComponent,
		ListServSuccessComponent,
		PublicHeaderComponent,
		PublicFooterComponent,
		...hospitalsAtHomeComponents,
		VaccineAttestationComponent,
		CallbackComponent,
		PublicErrorComponent,
		NursingHomeFormComponent,
		AccFormComponent,
		VmapSuccesComponent,
		CagAlzheimerComponent,
		CAGLandingSuccessPageComponent,
		PointOfContactComponent,
	],
	imports: [
		ToastrModule.forRoot(),
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		QnpCommonModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		NgbModule,
		QnpLibCommonModule,
		FileUploadModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ESSInterceptor,
			multi: true,
		},
		PageService,
		Title,
		DatePipe,
		AlzheimersService,
		HospitalAtHomeService,
		VaccineAttestationService,
		FormService,
		WindowService,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	bootstrap: [AppComponent],
})
export class AppModule {}
