import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from 'lodash';

@Component({
	selector: 'app-cag-landing-success-page',
	templateUrl: './cag-landing-success-page.component.html',
	styleUrls: ['./cag-landing-success-page.component.css'],
})
export class CAGLandingSuccessPageComponent implements OnInit {
	submissionNumber: string;
	constructor(
		public activatedRoute: ActivatedRoute,
		private titleService: Title,
		private viewportScroller: ViewportScroller,
		private router: Router
	) {}

	ngOnInit() {
		this.viewportScroller.scrollToPosition([0, 0]);
		this.titleService.setTitle("Alzheimer's form Successfully Submitted");
		this.submissionNumber = window.history.state?.submissionRequest;
		if (isEmpty(this.submissionNumber)) {
			this.router.navigate(['cag-alzheimers/submission']);
		}
	}
}
