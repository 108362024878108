// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ess-loader {
	position: relative;
	top: 8em;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/page/page.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,QAAQ;AACT","sourcesContent":["ess-loader {\n\tposition: relative;\n\ttop: 8em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
