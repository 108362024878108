import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { isEmpty } from 'lodash';

@Component({
	selector: 'app-hospitals-at-home-success',
	templateUrl: './hospitals-at-home-success.component.html',
	styleUrls: ['./hospitals-at-home-success.component.css'],
})
export class HospitalsAtHomeSuccessComponent implements OnInit {
	confilctLists: any[] = [];
	subscribedLists: any[] = [];
	waveReqNumber: any;
	constructor(
		public activatedRoute: ActivatedRoute,
		private titleService: Title,
		private router: Router
	) {}

	ngOnInit() {
		this.titleService.setTitle('Acute Hospital Care at Home Waiver Successfully Submitted');
		this.activatedRoute.paramMap
			.pipe(
				tap(() => {
					const lists = window.history.state;
					this.waveReqNumber = lists.waiverRequestNumber;
					if (isEmpty(this.waveReqNumber))
						this.router.navigate(['acute-hospital-care-at-home/waiver']);
				})
			)
			.subscribe();
	}
}
