import { Component, OnInit, Input, Inject } from '@angular/core';
import CSVFileValidator from 'csv-file-validator';
import { APP_CONFIG, IAppEnvConfig } from '@qnp/qnp-common';
import {
	fileValidatorConfig,
	IFilterSelection,
	invalidFileErrorMessages,
} from '../../../../hospital-at-home';
import { isEmpty } from 'lodash';
import { HospitalAtHomeService } from '../../../../../../services/hospital-at-home.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-tier2-submission',
	templateUrl: './tier2-submission.component.html',
	styleUrls: ['./tier2-submission.component.css'],
})
export class Tier2SubmissionComponent implements OnInit {
	@Input() tier2SubmissionForm: any;
	DateArr = [];
	csvString: string;
	fileUploadErrors: any[];
	csvFileData: any;
	showDateWarning = false;
	filterSelectionData$: Observable<IFilterSelection>;

	constructor(
		@Inject(APP_CONFIG) private config: IAppEnvConfig,
		private hosptalSvc: HospitalAtHomeService
	) {}

	ngOnInit(): void {
		this.filterSelectionData$ = this.hosptalSvc.getHospitalMeasureSelection();
		this.generateWeeks();
	}

	formatDate(unformattedDate, isDisplay) {
		const date = new Date(unformattedDate);
		const month = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
		const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
		const year = date.getFullYear();

		const formattedDate = isDisplay
			? month + '/' + day + '/' + year
			: year + '-' + month + '-' + day;
		return formattedDate;
	}

	generateWeeks() {
		let startDate = new Date('2020/12/28 00:00');
		const today = new Date();
		let key = 0;

		while (startDate < today) {
			const fromDate = new Date(startDate);
			const toDate = new Date(fromDate);
			toDate.setDate(toDate.getDate() + 6);

			if (toDate >= today) return;

			const formattedFromDate = this.formatDate(fromDate, false);
			const formattedToDate = this.formatDate(toDate, false);

			const displayFromDate = this.formatDate(fromDate, true);
			const displayToDate = this.formatDate(toDate, true);

			const dateRangeObj = {
				key: key,
				from: formattedFromDate,
				to: formattedToDate,
				display: displayFromDate + ' - ' + displayToDate,
			};

			this.DateArr.push(dateRangeObj);
			startDate = new Date(toDate);
			startDate.setDate(startDate.getDate() + 1);
			key++;
		}
	}

	onFileUpload(event) {
		this.fileUploadErrors = [];
		this.csvFileData = [];
		const files = event.srcElement.files;
		CSVFileValidator(files[0], fileValidatorConfig)
			.then(csvData => {
				csvData.data = this.removeBlankSpaces(csvData.data);
				const fileHeaders = Object.keys(csvData.data[0]);
				this.constructErrorMessages(csvData);
				const csvArray = csvData.data; // Array of objects from file
				const fileHeaderValues = csvArray.reduce((acc, item) => {
					acc.push(...Object.values(item));
					return acc;
				}, []);
				this.csvString = [...fileHeaders, ...fileHeaderValues].toString();
			})
			.catch(err => {});
	}

	constructErrorMessages({ data, inValidMessages }) {
		this.fileUploadErrors = [...new Set(inValidMessages)];
		this.csvFileData = data;
		const checkMBiMid = data.some(item => {
			return isEmpty(item) ? true : isEmpty(item.beneficiary) && isEmpty(item.medicare);
		});
		if (checkMBiMid) {
			this.fileUploadErrors.push(invalidFileErrorMessages.invalidMbiMid);
		}
	}

	checkIfLate() {
		const measurePeriod = this.tier2SubmissionForm.controls['measurePeriod'].value;
		this.showDateWarning =
			Number(measurePeriod) === this.DateArr[this.DateArr.length - 1].key ? false : true;
	}

	removeBlankSpaces(data) {
		const reducedCsv = data.reduce((acc, item) => {
			const isEmptyRow =
				isEmpty(item.medicare) && isEmpty(item.beneficiary) && isEmpty(item.admissionDate);
			if (!isEmptyRow) {
				acc.push(item);
			}
			return acc;
		}, []);
		return reducedCsv;
	}
	handleFilterChange(filterType) {
		const filter: IFilterSelection = {
			filterSelected: true,
			filterType,
		};
		this.hosptalSvc.setHospitalMeasureSelection(filter);
	}
}
