import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';

import { Title } from '@angular/platform-browser';
import { HospitalAtHomeService } from '../../../services/hospital-at-home.service';

@Component({
	selector: 'app-hospitals-at-home-measure',
	templateUrl: './hospital-at-home-meaure.component.html',
	styleUrls: ['./hospital-at-home-meaure.component.css'],
})
export class HospitalsAtHomeMeasureComponent implements OnInit {
	constructor(
		public formBuilder: UntypedFormBuilder,
		private hosptalSvc: HospitalAtHomeService,
		private router: Router,
		private titleService: Title
	) {}

	ngOnInit() {
		const featureFlag = this.checkFeatureFlags('measures');
		this.titleService.setTitle('Acute Hospital Care at Home Reporting Measures.');
	}

	checkFeatureFlags(type: string) {
		this.hosptalSvc.checkFeatureFlag(type).subscribe(
			res => {},
			err => {
				this.router.navigate(['acute-hospital-care-at-home']);
			}
		);
	}
}
