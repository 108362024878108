import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-tier-type',
	templateUrl: './tier-type.component.html',
	styleUrls: ['./tier-type.component.css'],
})
export class TierTypeComponent {
	@Input() tierTypeForm: any;
}
