import { Observable } from 'rxjs';
import { isEmpty } from 'lodash';
import { Component, OnInit, Input, Inject } from '@angular/core';
import CSVFileValidator from 'csv-file-validator';
import { APP_CONFIG, IAppEnvConfig } from '@qnp/qnp-common';
import {
	fileValidatorConfig,
	IFilterSelection,
	invalidFileErrorMessages,
} from '../../../../hospital-at-home';
import { HospitalAtHomeService } from '../../../../../../services/hospital-at-home.service';

@Component({
	selector: 'app-tier1-submission',
	templateUrl: './tier1-submission.component.html',
	styleUrls: ['./tier1-submission.component.css'],
})
export class Tier1SubmissionComponent implements OnInit {
	@Input() tier1SubmissionForm: any;
	DateArr = [];
	csvString: string;
	fileUploadErrors: any[];
	csvFileData: any;
	showDateWarning = false;
	filterSelectionData$: Observable<IFilterSelection>;

	constructor(
		@Inject(APP_CONFIG) private config: IAppEnvConfig,
		private hosptalSvc: HospitalAtHomeService
	) {}

	ngOnInit(): void {
		this.filterSelectionData$ = this.hosptalSvc.getHospitalMeasureSelection();
		this.generateMonths();
	}

	formatDate(unformattedDate, isDisplay) {
		const date = new Date(unformattedDate);
		const month = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
		const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
		const year = date.getFullYear();

		const formattedDate = isDisplay
			? month + '/' + day + '/' + year
			: year + '-' + month + '-' + day;
		return formattedDate;
	}

	generateMonths() {
		let startDate = new Date('2020/12/01 00:00');
		const today = new Date();
		let key = 0;

		while (startDate < today) {
			const fromDate = new Date(startDate);
			const toDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);

			if (toDate >= today) return;

			const formattedFromDate = this.formatDate(fromDate, false);
			const formattedToDate = this.formatDate(toDate, false);

			const displayFromDate = this.formatDate(fromDate, true);
			const displayToDate = this.formatDate(toDate, true);

			let dateRangeObj;

			if (formattedFromDate === '2020-12-01') {
				dateRangeObj = {
					key: key,
					from: '2020-11-23',
					to: formattedToDate,
					display: '11/23/2020' + ' - ' + displayToDate,
				};
			} else {
				dateRangeObj = {
					key: key,
					from: formattedFromDate,
					to: formattedToDate,
					display: displayFromDate + ' - ' + displayToDate,
				};
			}

			this.DateArr.push(dateRangeObj);
			startDate = new Date(toDate);
			startDate.setDate(startDate.getDate() + 1);
			key++;
		}
	}

	onFileUpload(event) {
		this.fileUploadErrors = [];
		this.csvFileData = [];
		const files = event.srcElement.files;
		CSVFileValidator(files[0], fileValidatorConfig)
			.then(csvData => {
				csvData.data = this.removeBlankSpaces(csvData.data);
				const fileHeaders = Object.keys(csvData.data[0]);
				this.constructErrorMessages(csvData);
				const csvArray = csvData.data; // Array of objects from file
				const fileHeaderValues = csvArray.reduce((acc, item) => {
					acc.push(...Object.values(item));
					return acc;
				}, []);
				this.csvString = [...fileHeaders, ...fileHeaderValues].toString();
			})
			.catch(err => {});
	}

	constructErrorMessages({ data, inValidMessages }) {
		this.fileUploadErrors = [...new Set(inValidMessages)];
		this.csvFileData = data;
		const checkMBiMid = data.some(item => {
			return isEmpty(item) ? true : isEmpty(item.beneficiary) && isEmpty(item.medicare);
		});
		if (checkMBiMid) {
			this.fileUploadErrors.push(invalidFileErrorMessages.invalidMbiMid);
		}
		// Aggregate decimal format errors
		const decimalErrors = this.checkDecimalBeneficiary(
			data,
			'beneficiary',
			'State Medicaid Identifier'
		);
		if (decimalErrors.length > 0) {
			this.fileUploadErrors.push(...decimalErrors);
		}
	}

	checkDecimalBeneficiary(jsonData, key, displayName) {
		return jsonData.reduce((messages, item, index) => {
			if (item[key]?.includes('.')) {
				const rowNumber = index + 1; // Adjust index to match row numbers
				messages.push(
					`Row ${rowNumber}: '${displayName}' should be a whole number. Format the cell as text .`
				);
			}
			return messages;
		}, []);
	}

	checkIfLate() {
		const measurePeriod = this.tier1SubmissionForm.controls['measurePeriod'].value;
		this.showDateWarning =
			Number(measurePeriod) === this.DateArr[this.DateArr.length - 1].key ? false : true;
	}

	removeBlankSpaces(data) {
		const reducedCsv = data.reduce((acc, item) => {
			const isEmptyRow =
				isEmpty(item.medicare) && isEmpty(item.beneficiary) && isEmpty(item.admissionDate);
			if (!isEmptyRow) {
				acc.push(item);
			}
			return acc;
		}, []);
		return reducedCsv;
	}

	handleFilterChange(filterType) {
		const filter: IFilterSelection = {
			filterSelected: true,
			filterType,
		};
		this.hosptalSvc.setHospitalMeasureSelection(filter);
	}
}
