import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { APP_CONFIG, IAppEnvConfig } from '@qnp/qnp-common';
import {
	AlzheimersFeatureResponse,
	CagGeneralInfoValidationRequest,
	CagGeneralInfoValidationResponse,
} from '../public/models/general-info.model';
import { Observable } from 'rxjs';

@Injectable()
export class AlzheimersService {
	constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: IAppEnvConfig) {}

	publicContentApiUrl = `${this.config.publicGateway}${this.config.publicContentApi}`;

	submitAlzeihmerForm(payload) {
		const url = `${this.publicContentApiUrl}/cagAlzheimers`;
		return this.http.post(url, payload);
	}

	verifyGeneralInfo(
		payload: CagGeneralInfoValidationRequest
	): Observable<CagGeneralInfoValidationResponse> {
		const url = `${this.publicContentApiUrl}/cagAlzheimers/validate`;
		return this.http.post<CagGeneralInfoValidationResponse>(url, payload);
	}

	showAlzheimersFeature(): Observable<AlzheimersFeatureResponse> {
		const url = `${this.publicContentApiUrl}/cagAlzheimers/loadFeatureFlag`;
		const headers = new HttpHeaders().set('cachepurge', 'true');
		return this.http.get<AlzheimersFeatureResponse>(url, { headers });
	}
}
