import { Validators } from '@angular/forms';
export const appTierTypes = {
	tier1: 'Acute Hospital Care at Home - Tier 1',
	tier2: 'Acute Hospital Care at Home - Tier 2',
};

export const invalidFileErrorMessages = {
	invalidMbiMid: 'CSV file contains missing MBI and/or Medicaid ID.',
	invalidQ1aRowCount: 'CSV file does not match answer provided in question 1a.',
	invalidQ1bRowCount: 'CSV file does not match answer provided in question 1b.',
	invalidQ1cRowCount: 'CSV file does not match answer provided in question 1c.',
};

export const fileValidatorConfig = {
	headers: [
		{
			name: 'Medicare Beneficiary Identifier (MBI)',
			inputName: 'medicare',
			required: false,
			headerError: function () {
				return `CSV file contains missing column header: Medicare Beneficiary Identifier (MBI).`;
			},
			validate: function (medicare) {
				return medicare.length > 20 ? false : true;
			},
			validateError: function (headerName, rowNumber, columnNumber) {
				return `CSV file contains MBI that exceeds maximum character count in row ${rowNumber}.`;
			},
		},
		{
			name: 'State Medicaid Identifier',
			inputName: 'beneficiary',
			required: false,
			headerError: function () {
				return `CSV file contains missing column header: State Medicaid Identifier.`;
			},
			validate: function (beneficiary) {
				return beneficiary.length > 20 ? false : true;
			},
			validateError: function (headerName, rowNumber, columnNumber) {
				return `CSV file contains Medicaid ID that exceeds maximum character count in row ${rowNumber}.`;
			},
		},
		{
			name: 'Admission Date (MM/DD/YYYY)',
			inputName: 'admissionDate',
			required: false,
			headerError: function () {
				return `CSV file contains missing column header: Admission Date (MM/DD/YYYY).`;
			},
		},
	],
};

export const attestationTitles: string[] = [
	'CEO',
	'CFO',
	'CMO',
	'Chief Nursing Officer',
	'President',
	'Other',
];

export interface IFilterSelection {
	filterSelected: boolean;
	filterType?: 'Yes' | 'No';
}

export const numericQuestionValidators = [
	Validators.required,
	Validators.maxLength(14),
	Validators.pattern('^(0|[1-9][0-9]*)$'),
];
