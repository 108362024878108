import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { APP_CONFIG, IAppEnvConfig, ToastrService } from '@qnp/qnp-common';

@Injectable({
	providedIn: 'root',
})
export class EnableFeatureGuardService implements CanActivate {
	publicContentApiUrl = `${this.config.publicGateway}${this.config.publicContentApi}`;
	features = {
		vrap: `${this.publicContentApiUrl}/vmap/featureStatus`,
	};

	constructor(
		private httpClient: HttpClient,
		@Inject(APP_CONFIG) private config: IAppEnvConfig,
		private toastr: ToastrService
	) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		const currentFeature = route.data['feature'];
		return this.httpClient.get(this.features[currentFeature]).pipe(
			map(res => {
				return true;
			}),
			catchError(err => {
				const message = err?.error ? err.error : 'This feature is inactive.';
				this.toastr.error(message);
				return of(false);
			})
		);
	}
}
