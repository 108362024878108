import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormService } from '../../../../services/form.service';
import { attestationTitles } from '../../hospital-at-home';

@Component({
	selector: 'app-attestation',
	templateUrl: './attestation.component.html',
	styleUrls: ['./attestation.component.css'],
})
export class AttestationComponent implements OnInit {
	@Input() attestationForm: any;
	@Input() addInfoRequired: boolean;
	attestationTitles: string[] = attestationTitles;
	showOtherTitle = false;

	constructor(private formSvc: FormService) {}

	ngOnInit() {
		if (this.formSvc.checkItemExists('ahcah-waiver-form')) {
			this.updateTitleDropDown();
		}
	}

	private updateTitleDropDown() {
		if (this.attestationForm?.value?.attestingTitle === attestationTitles[5]) {
			this.showOtherTitle = true;
			this.updateFormValidity();
		}
	}

	onTitleChange(event) {
		this.showOtherTitle = event.target.value === attestationTitles[5];
		this.updateFormValidity();
	}

	private updateFormValidity() {
		if (!this.showOtherTitle) {
			this.attestationForm.get('otherTitle').setValidators([]);
			this.attestationForm.get('otherTitle').updateValueAndValidity({ emitEvent: false });
		} else if (this.attestationForm.controls.otherTitle.errors === null) {
			this.attestationForm
				.get('otherTitle')
				.setValidators([
					Validators.required,
					Validators.maxLength(255),
					Validators.pattern(`^[a-zA-Z0-9\\-'.\\s]*$`),
				]);
			this.attestationForm.get('otherTitle').updateValueAndValidity({ emitEvent: false });
		}
	}
}
