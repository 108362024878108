// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Override CMS design system */
.ds-c-label {
	max-width: none;
	font-weight: 400;
}

.ds-c-field {
	max-width: none;
}

legend {
	font-size: 1rem;
}

.loader {
	position: fixed;
	display: none;
	z-index: 100;
	top: 0;
	left: 0;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.5);
	width: 100%;
	height: 100%;
}
.loader.loading {
	display: flex;
	justify-content: center;
	align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/hospitals-at-home/waiver/hospitals-at-home.component.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;CACC,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,aAAa;CACb,YAAY;CACZ,MAAM;CACN,OAAO;CACP,uBAAuB;CACvB,0CAA0C;CAC1C,WAAW;CACX,YAAY;AACb;AACA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":["/* Override CMS design system */\n.ds-c-label {\n\tmax-width: none;\n\tfont-weight: 400;\n}\n\n.ds-c-field {\n\tmax-width: none;\n}\n\nlegend {\n\tfont-size: 1rem;\n}\n\n.loader {\n\tposition: fixed;\n\tdisplay: none;\n\tz-index: 100;\n\ttop: 0;\n\tleft: 0;\n\tjustify-content: center;\n\tbackground-color: rgba(255, 255, 255, 0.5);\n\twidth: 100%;\n\theight: 100%;\n}\n.loader.loading {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
