import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-public',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './public.component.html',
	styleUrls: ['./public.component.css'],
})
export class PublicComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
