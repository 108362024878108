// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header {
	display: flex;
	flex-direction: column;
}

section {
	position: absolute;
	width: 650px;
	z-index: 15;
	top: 40%;
	left: 50%;
	margin: -100px 0 0 -415px;
}
a {
	color: #dc8100;
	text-decoration: none;
}
a:hover {
	color: #333;
	text-decoration: none;
}

.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/components/error-component/error-component.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,WAAW;CACX,QAAQ;CACR,SAAS;CACT,yBAAyB;AAC1B;AACA;CACC,cAAc;CACd,qBAAqB;AACtB;AACA;CACC,WAAW;CACX,qBAAqB;AACtB;;AAEA;CACC,eAAe;CACf,SAAS;CACT,WAAW;AACZ","sourcesContent":[".app-header {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\nsection {\n\tposition: absolute;\n\twidth: 650px;\n\tz-index: 15;\n\ttop: 40%;\n\tleft: 50%;\n\tmargin: -100px 0 0 -415px;\n}\na {\n\tcolor: #dc8100;\n\ttext-decoration: none;\n}\na:hover {\n\tcolor: #333;\n\ttext-decoration: none;\n}\n\n.footer {\n\tposition: fixed;\n\tbottom: 0;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
