import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HospitalAtHomeService } from '../../../../../services/hospital-at-home.service';

@Component({
	selector: 'app-submission-history',
	templateUrl: './submission-history.component.html',
	styleUrls: ['./submission-history.component.css'],
})
export class SubmissionHistoryComponent implements OnInit, OnDestroy {
	@Input() searchItems: any;
	submissions = [];
	submissionSuccess: boolean = false;
	subscription = new Subscription();

	constructor(private hosptalSvc: HospitalAtHomeService) {}

	ngOnInit() {
		this.getSubmissionHistory();
		this.subscription.add(
			this.hosptalSvc.refreshSubmissionHistory
				.asObservable()
				.subscribe(() => this.getSubmissionHistory())
		);
	}

	getSubmissionHistory() {
		this.hosptalSvc.getSubmissionHistory(this.searchItems.ccn).subscribe(
			(data: any) => {
				this.submissions = data;
			},
			err => {}
		);
	}

	formatSubmissionDate(unformattedDate) {
		const formatKey = unformattedDate.includes('.000-0500') ? '.000-0500' : '.000-0400';
		const date = new Date(unformattedDate.replace(formatKey, ''));
		const month = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
		const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
		const year = date.getFullYear();
		const formattedDate = month + '/' + day + '/' + year;
		return formattedDate;
	}

	formatMeasureDate(unformattedDate) {
		const date = unformattedDate;
		const [year, month, day] = date.split('-');
		const formattedDate = month + '/' + day + '/' + year;
		return formattedDate;
	}

	closeAlert() {
		this.submissionSuccess = false;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
