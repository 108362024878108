// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-message {
	display: block;
	text-align: center;
	padding: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/files/files.component.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,kBAAkB;CAClB,aAAa;AACd","sourcesContent":[".download-message {\n\tdisplay: block;\n\ttext-align: center;\n\tpadding: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
