// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
	position: fixed;
	display: none;
	z-index: 100;
	top: 0;
	left: 0;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.5);
	width: 100%;
	height: 100%;
}
.loader.loading {
	display: flex;
	justify-content: center;
	align-items: center;
}

label {
	font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/vaccine-attestation/vaccine-attestation.component.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,aAAa;CACb,YAAY;CACZ,MAAM;CACN,OAAO;CACP,uBAAuB;CACvB,0CAA0C;CAC1C,WAAW;CACX,YAAY;AACb;AACA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".loader {\n\tposition: fixed;\n\tdisplay: none;\n\tz-index: 100;\n\ttop: 0;\n\tleft: 0;\n\tjustify-content: center;\n\tbackground-color: rgba(255, 255, 255, 0.5);\n\twidth: 100%;\n\theight: 100%;\n}\n.loader.loading {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\nlabel {\n\tfont-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
