import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'app-nursing-home-form',
	templateUrl: './nursing-home-form.component.html',
	styleUrls: ['./nursing-home-form.component.css'],
})
export class NursingHomeFormComponent implements OnInit {
	@Input() nursingHomeForm: any;
	constructor() {}
	facilityDetails = [
		{
			title: 'What is your facility’s overall percentage of staff vaccinated?',
			controlName: 'eq16a',
		},
		{
			title: 'How many staff meet the requirement to be vaccinated?',
			controlName: 'eq16b',
		},
		{
			title: 'How many staff have a medical exemption?',
			controlName: 'eq16c',
		},
		{
			title: 'How many staff have a non-medical exemption?',
			controlName: 'eq16d',
		},
		{
			title: 'How many staff have an approved temporary delay?',
			controlName: 'eq16e',
		},
		{
			title: 'How many staff are not included in any of these categories?',
			controlName: 'eq16f',
		},
		{
			title: 'How many residents have had a COVID-19 infection in the last four weeks?',
			controlName: 'eq17a',
		},
		{
			title: 'Of those residents with a COVID-19 infection, how many have died?',
			controlName: 'eq18a',
		},
		{
			title: 'Of those residents with a COVID-19 infection, how many have been hospitalized?',
			controlName: 'eq19a',
		},
	];

	ngOnInit(): void {
		this.nursingHomeForm.get('ccn').addValidators(this.ccnValidator);
		this.listenForNursingHomeFormChanges();
	}

	ccnValidator = (control: AbstractControl) => {
		return control.value?.length == 6 || control.value?.length == 10 ? null : { invalidCcn: true };
	};

	private listenForNursingHomeFormChanges() {
		const eq5aControl = <UntypedFormControl>this.nursingHomeForm.get('eq5a');
		this.addDynamicRadioControls(eq5aControl, 'eq5b');
		const eq6aControl = <UntypedFormControl>this.nursingHomeForm.get('eq6a');
		this.addDynamicRadioControls(eq6aControl, 'eq6b');
		const eq8aControl = <UntypedFormControl>this.nursingHomeForm.get('eq8a');
		this.addDynamicRadioControls(eq8aControl, 'eq8b');
		const eq9aControl = <UntypedFormControl>this.nursingHomeForm.get('eq9a');
		this.addDynamicRadioControls(eq9aControl, 'eq9b');
	}

	private addDynamicRadioControls(control, newControl) {
		control.valueChanges
			.pipe(
				tap(value => {
					if (value === 'Yes') {
						this.nursingHomeForm.addControl(
							newControl,
							new UntypedFormControl('', [Validators.required])
						);
					} else {
						this.nursingHomeForm.removeControl(newControl);
					}
				})
			)
			.subscribe();
	}

	omitNumber(event, field?) {
		const key = event.which;
		const rejectKeys = [43, 45, 101];
		if (field !== 'eq16a') rejectKeys.push(46);
		if (rejectKeys.includes(key)) return false;
	}
}
