import { switchMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subject, Observable, Subscription, forkJoin } from 'rxjs';
import { PageService } from '../../../services/page.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PagerService } from '@qnp/qnp-common';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
	searchTerm$ = new Subject<string>();
	searchValue: string;

	pagerPage: any = {};
	pagerFile: any = {};

	actions: Observable<any> = this.searchTerm$.asObservable();
	pageSource$: any = new Subject<any>();
	subscription: Subscription;
	pagedItems: any[];
	pagedFileItems: any[];
	loading = false;
	noSearch = true;

	currentActivePage: number;

	searchResultsPages: any = {
		data: [],
		page: null,
		total: null,
		itemsPerPage: null,
	};
	searchResultsFiles: any = {
		data: [],
		page: null,
		total: null,
		itemsPerPage: null,
	};
	@ViewChild('qnpSearch') searchInput: ElementRef;

	constructor(
		private publicService: PageService,
		private route: ActivatedRoute,
		private pagerService: PagerService,
		private pagerFileService: PagerService,
		private titleService: Title
	) {}

	ngOnInit() {
		this.titleService.setTitle('Search QualityNet');
		this.listenForPageSelection();
		this.listenForSearch();
		this.route.queryParams.subscribe(queryParams => {
			const q = queryParams['q'];
			if (q && q !== '' && q !== ' ') {
				this.loading = true;
				this.noSearch = false;
				this.searchValue = q;
				this.getAllListRequests();
			}
		});
	}
	private listenForSearch() {
		this.actions
			.pipe(
				debounceTime(700),
				distinctUntilChanged(),
				switchMap(searchTerm => {
					this.searchValue = searchTerm;
					this.noSearch = false;
					return searchTerm;
				})
			)
			.subscribe(() => {
				this.getAllListRequests();
			});
	}
	getAllListRequests() {
		const fileReq$ = this.publicService.searchEntries('file', this.searchValue, 1);
		const pageReq$ = this.publicService.searchEntries('page', this.searchValue, 1);
		forkJoin([fileReq$, pageReq$]).subscribe(
			([fileReqData, pageReqData]) => {
				this.setPagerConfig({ ...fileReqData, type: 'file' });
				this.setPagerConfig({ ...pageReqData, type: 'page' });
			},
			err => {
				this.loading = false;
			}
		);
	}
	private listenForPageSelection() {
		this.pageSource$
			.asObservable()
			.pipe(
				switchMap((pageEvent: any) =>
					this.publicService.searchEntries(pageEvent.type, this.searchValue, pageEvent.page)
				)
			)
			.subscribe(
				results => {
					this.setPagerConfig(results);
				},
				err => {
					this.loading = false;
				}
			);
	}

	private setPagerConfig(pagerData) {
		const itemsPerPage = 10;
		switch (pagerData.type) {
			case 'page':
				this.loading = false;
				this.searchResultsPages = pagerData;
				this.pagedItems = pagerData.data;
				this.pagerPage = this.pagerService.getPager(pagerData.total, pagerData.page, itemsPerPage);
				break;
			case 'file':
				this.loading = false;
				this.searchResultsFiles = pagerData;
				this.pagedFileItems = pagerData.data;
				this.pagerFile = this.pagerFileService.getPager(
					pagerData.total,
					pagerData.page,
					itemsPerPage
				);
				break;
			default:
		}
	}
	errorHandler(error: any): void {
		this.searchTerm$.next(error);
	}

	searchMe($event) {
		this.searchTerm$.next($event.target.value);
	}

	setPage(page: number, type?) {
		if (!page || page === this.currentActivePage) return;
		this.currentActivePage = page;
		this.pageSource$.next({ page, type });
	}

	ngAfterViewInit() {
		this.route.queryParams.subscribe(queryParams => {
			const q = queryParams['q'];
			if (q && q !== '' && q !== ' ') {
				this.searchInput.nativeElement.value = q;
			}
		});
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this.pagerFileService.resetCurrentPage();
		this.pagerService.resetCurrentPage();
	}
}
