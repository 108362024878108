import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-termsconditions',
	templateUrl: './termsconditions.component.html',
	styleUrls: ['./termsconditions.component.css'],
})
export class TermsconditionsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}

	/**
	 * Closes the current browser window displaying the Terms and Conditions.
	 */
	closeTermsAndConditionsWindow() {
		window.close();
	}
}
