export const FacilityTypes = [
	'Ambulatory Surgical Center',
	'Comprehensive Outpatient Rehabilitation Facility',
	'Community Mental Health Center',
	'Critical Access Hospital',
	'End Stage Renal Dialysis Facility',
	'Federally Qualified Health Center',
	'Home Health Agency',
	'Hospice',
	'Hospital',
	'Intermediate Care Facility for Individuals with Intellectual Disabilities',
	'Outpatient Therapy Facilities',
	'Psychiatric Residential Treatment Facility',
	'Rural Health Clinic',
	'Long Term Care Facilities',
];
