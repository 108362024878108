// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Override CMS design system */
.ds-c-label {
	max-width: none;
	font-weight: 400;
}

.ds-c-field {
	max-width: none;
}

textarea {
	height: 100px;
}

legend {
	font-size: 1rem;
}

.qnp-no-wrap {
	white-space: nowrap !important;
}

.qnp-calendar-button {
	background: url('/assets/img/calender.svg') no-repeat center;
	margin-top: 4px;
	border-radius: 3px;
	width: 40px;
	border-color: #000;
}

.qnp-file {
	overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/hospitals-at-home/measure/measure-collection-submission/application-tier/tier2-submission/tier2-submission.component.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;CACC,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,4DAA4D;CAC5D,eAAe;CACf,kBAAkB;CAClB,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":["/* Override CMS design system */\n.ds-c-label {\n\tmax-width: none;\n\tfont-weight: 400;\n}\n\n.ds-c-field {\n\tmax-width: none;\n}\n\ntextarea {\n\theight: 100px;\n}\n\nlegend {\n\tfont-size: 1rem;\n}\n\n.qnp-no-wrap {\n\twhite-space: nowrap !important;\n}\n\n.qnp-calendar-button {\n\tbackground: url('/assets/img/calender.svg') no-repeat center;\n\tmargin-top: 4px;\n\tborder-radius: 3px;\n\twidth: 40px;\n\tborder-color: #000;\n}\n\n.qnp-file {\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
