import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class FormService {
	constructor() {}

	public assignValueToControl(formControl: UntypedFormControl, value: any, validOnly: boolean) {
		const oldValue = formControl.value;

		formControl.setValue(value);

		if (validOnly && formControl.invalid) {
			formControl.setValue(oldValue);
		}
	}

	public setToLocalStorage(
		formGroup: UntypedFormGroup,
		id: string,
		excludeControls?: string[],
		maxDaysAge?: number,
		validOnly?: boolean
	) {
		let data = {};

		if (maxDaysAge) {
			const validUntil = new Date();
			validUntil.setDate(validUntil.getDate() + maxDaysAge);

			data['VALID_UNTIL'] = validUntil.toISOString();
		}

		for (const name in formGroup.controls) {
			if (excludeControls && excludeControls.includes(name)) continue;

			const control = formGroup.controls[name];
			if (validOnly && control.invalid) continue;

			data[name] = control.value;
		}

		data = JSON.stringify(data);
		localStorage.setItem(id, <string>data);
	}

	public getFromLocalStorage(
		formGroup: UntypedFormGroup,
		id: string,
		excludeControls?: string[],
		maxDaysAge?: number,
		validOnly?: boolean
	) {
		let data = localStorage.getItem(id);
		if (!data) return;

		try {
			data = JSON.parse(data);

			// Check if Max-Age is exceeded
			const validUntil = data['VALID_UNTIL'];
			if (data['VALID_UNTIL'] && validUntil < new Date().toISOString()) {
				localStorage.removeItem(id);
				return;
			}

			// Assign items to each accordingcontrol
			for (const name in <any>data) {
				if (!(name in formGroup.controls)) continue;
				if (excludeControls && excludeControls.includes(name)) continue;

				const control = <UntypedFormControl>formGroup.controls[name];
				this.assignValueToControl(control, data[name], validOnly);
			}
		} catch (e) {
			console.log(`Couldn't parse LocalStorage data for formGroup '${id}'`);
			console.log(e);
		}
	}

	public syncWithLocalStorage(
		formGroup: UntypedFormGroup,
		id: string,
		excludeControls?: string[],
		maxDaysAge?: number,
		validOnly: boolean = true
	) {
		this.getFromLocalStorage(formGroup, id, excludeControls, maxDaysAge, validOnly);

		formGroup.valueChanges.subscribe(values => {
			this.setToLocalStorage(formGroup, id, excludeControls, maxDaysAge, validOnly);
		});
	}

	public removeFromLocalStorage(id: string) {
		localStorage.removeItem(id);
	}

	public checkItemExists(id: string) {
		const data = localStorage.getItem(id);
		if (!data) return false;

		return true;
	}
}
