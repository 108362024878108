import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-tier1-form',
	templateUrl: './tier1-form.component.html',
	styleUrls: ['./tier1-form.component.css'],
})
export class Tier1FormComponent {
	@Input() tier1Form: any;
}
