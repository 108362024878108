// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qnp-alert-close {
	position: absolute;
	right: 0;
	top: 0.5rem;
	font-size: 1.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/hospitals-at-home/measure/measure-collection-submission/submission-history/submission-history.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,QAAQ;CACR,WAAW;CACX,iBAAiB;AAClB","sourcesContent":[".qnp-alert-close {\n\tposition: absolute;\n\tright: 0;\n\ttop: 0.5rem;\n\tfont-size: 1.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
