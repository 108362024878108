import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

interface Ilist {
	conflict: [];
	subscribed: [];
}

@Component({
	selector: 'app-listserv-success',
	templateUrl: './listserv-success.component.html',
	styleUrls: ['./listserv-success.component.css'],
})
export class ListServSuccessComponent implements OnInit {
	confilctLists: any[] = [];
	subscribedLists: any[] = [];
	constructor(
		private router: Router,
		public activatedRoute: ActivatedRoute,
		private titleService: Title
	) {}

	ngOnInit() {
		this.titleService.setTitle('Successfully Subscribed');
		this.activatedRoute.paramMap
			.pipe(
				tap(() => {
					const lists = window.history.state as Ilist;
					if (!lists.conflict && !lists.subscribed) this.router.navigate(['']);
					this.confilctLists = lists.conflict ? lists.conflict : [];
					this.subscribedLists = lists.subscribed ? lists.subscribed : [];
				})
			)
			.subscribe();
	}
}
