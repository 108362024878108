import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TealiumUtagService } from '@qnp/qnp-common';

declare var $: any;

@Component({
	selector: 'app-public-header',
	template: `
		<button type="button" id="skipNav" class="skip-nav">Skip to content</button>
		<div class="ds-c-alert">
			<div class="ds-c-alert__body">
				<p class="ds-c-alert__text">
					Using Internet Explorer to access QualityNet is not recommended and could affect your
					experience. We suggest you use Firefox, Chrome, or Edge to get the most from QualityNet.
				</p>
			</div>
		</div>
		<header>
			<nav
				class="qnp-header"
				onmouseleave="hideDropdown('#qualityProgramsMenu'); hideDropdown('#helpMenu')"
			>
				<div class="primary-nav">
					<div class="ds-l-container-fluid">
						<div class="ds-l-row">
							<div class="ds-l-md-col--4 ds-l-lg-col--3 ds-l-col--8">
								<a href="/"
									><img
										src="/images/QualityNet-logo.svg"
										class="qualitynet-logo"
										alt="CMS.gov QualityNet logo"
								/></a>
							</div>
							<div class="ds-l-col--4 mobile-menu">
								<button class="ds-c-button" type="button" onclick="toggleMobileNav('mobile-nav')">
									Menu
								</button>
							</div>
							<div class="ds-l-md-col--5 ds-l-lg-col--3 ds-l-md-col--4" *ngIf="!hideSearch">
								<label class="sr-only" for="qnpNavSearch">Search QualityNet</label>
								<input
									type="text"
									id="qnpNavSearch"
									onkeydown="onClickSearch()"
									class="ds-c-field search-bar"
									placeholder="Search QualityNet"
								/>
								<a
									href="/search"
									id="qnpNavSearchBtn"
									class="search-button ds-c-button--transparent"
									title="Search QualityNet"
									><img class="search-icon" src="/images/search-icon-blue.svg" alt="Search icon"
								/></a>
							</div>
							<div class="ds-l-col--3 tablet-menu">
								<button class="ds-c-button" type="button" onclick="toggleMobileNav('mobile-nav')">
									Menu
								</button>
							</div>
							<div class="ds-l-md-col--6 mobile-nav" *ngIf="!hideNavItems">
								<ul class="qnp-nav">
									<li>
										<button
											class="button-link"
											onclick="showDropdown('#qualityProgramsMenu'); hideDropdown('#helpMenu');"
											onmouseover="showDropdown('#qualityProgramsMenu'); hideDropdown('#helpMenu');"
										>
											Quality Programs&nbsp;<img
												src="/images/arrow-down.svg"
												alt="Dropdown arrow"
												class="dropdown-arrow"
											/>
										</button>
									</li>
									<li>
										<button
											class="button-link"
											onclick="showDropdown('#helpMenu'); hideDropdown('#qualityProgramsMenu');"
											onmouseover="showDropdown('#helpMenu'); hideDropdown('#qualityProgramsMenu');"
										>
											Help&nbsp;<img
												src="/images/arrow-down.svg"
												alt="Dropdown arrow"
												class="dropdown-arrow"
											/>
										</button>
									</li>
									<li>
										<a
											href="/getting-started"
											class="ds-c-button ds-c-button--secondary ds-c-button--small"
											>Register</a
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div id="#qualityProgramsMenu" class="quality-programs-menu">
					<div class="ds-l-container">
						<div class="ds-l-row">
							<div class="ds-l-col--12 ds-u-text-align--right close-link-container">
								<button class="close-link" onclick="hideDropdown('#qualityProgramsMenu')">
									Close
								</button>
							</div>
							<div class="ds-l-lg-col--4">
								<h4>Hospitals - Inpatient</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/inpatient">Overview</a></li>
									<li><a href="/inpatient/iqr">Hospital Inpatient Quality Reporting (IQR)</a></li>
									<li><a href="/inpatient/hvbp">Hospital Value-Based Purchasing (HVBP)</a></li>
									<li>
										<a href="/inpatient/hac">Hospital-Acquired Condition Reduction (HACRP)</a>
									</li>
									<li><a href="/inpatient/hrrp">Hospital Readmissions Reduction (HRRP)</a></li>
								</ul>
								<h4>Hospitals - Outpatient</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/outpatient">Overview</a></li>
									<li><a href="/outpatient/oqr">Hospital Outpatient Quality Reporting (OQR)</a></li>
								</ul>
							</div>
							<div class="ds-l-lg-col--4">
								<h4>Ambulatory Surgical Centers</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/asc">Overview</a></li>
									<li>
										<a href="/asc/ascqr">Ambulatory Surgical Center Quality Reporting (ASCQR)</a>
									</li>
								</ul>
								<h4>PPS-Exempt Cancer Hospitals</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/pch">Overview</a></li>
									<li>
										<a href="/pch/pchqr">PPS-Exempt Cancer Hospital Quality Reporting (PCHQR)</a>
									</li>
								</ul>
							</div>
							<div class="ds-l-lg-col--4">
								<h4>ESRD Facilities</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/esrd">Overview</a></li>
									<li><a href="/esrd/esrdqip">ESRD Quality Incentive (QIP)</a></li>
								</ul>
								<h4>Inpatient Psychiatric Facilities</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/ipf">Overview</a></li>
									<li>
										<a href="/ipf/ipfqr"
											>Inpatient Psychiatric Facility Quality Reporting (IPFQR)</a
										>
									</li>
								</ul>
								<h4>Program Resource System (PRS) 2.0</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/data-dictionary">Data Dictionary</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div id="#helpMenu" class="help-menu">
					<div class="ds-l-container">
						<div class="ds-l-row">
							<div class="ds-l-col--12 ds-u-text-align--right close-link-container">
								<button class="close-link" onclick="hideDropdown('#helpMenu')">Close</button>
							</div>
							<div class="ds-l-lg-col--4">
								<h4>Getting Started</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/getting-started#tab1">Registration</a></li>
									<li><a href="/getting-started#tab2">I am an HQR User</a></li>
									<li><a href="/getting-started#tab3">I am an EQRS User</a></li>
								</ul>

								<h4>Training & Guides</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/training-guides#tab1">QualityNet Training</a></li>
									<li><a href="/training-guides#tab2">QualityNet Secure Portal</a></li>
									<li><a href="/training-guides#tab3">Secure File Transfer</a></li>
									<li><a href="/training-guides#tab4">QualityNet Events Center</a></li>
								</ul>
							</div>
							<div class="ds-l-lg-col--4">
								<h4>Known Issues & Maintenance</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/known-issues-maintenance#tab1">Known Issues</a></li>
									<li><a href="/known-issues-maintenance#tab2">System Maintenance</a></li>
								</ul>

								<h4>QualityNet Support</h4>
								<ul class="ds-c-list--bare">
									<li><a href="/support">QualityNet Support</a></li>
								</ul>
							</div>
							<div class="ds-l-lg-col--4">
								<h4>Question & Answer Tools</h4>
								<ul class="ds-c-list--bare">
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa"
											>Q&A Tool Main Page</a
										>
									</li>
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa?id=ask_a_question"
											>Ask a Question</a
										>
									</li>
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa?id=qa_request_status"
											>My Questions</a
										>
									</li>
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa?kb_id=bb8e900fdbb6bf0092d5365e7c9619a9&id=kb_view2"
											>Hospitals - Inpatient</a
										>
									</li>
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa?kb_id=ef1db733dbbe33409cad3a8c7c961924&id=kb_view2"
											>Hospitals - Outpatient</a
										>
									</li>
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa?kb_id=a391f8b9dbd37b0058d13ebc7c961949&id=kb_view2"
											>Ambulatory Surgical Centers</a
										>
									</li>
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa?kb_id=ef37ab0adb5c885458d13ebc7c961912&id=kb_view2"
											>PPS-Exempt Cancer Hospitals</a
										>
									</li>
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa?kb_id=4d33c8fedbf67f0092d5365e7c961986&id=kb_view2"
											>ESRD Facilities</a
										>
									</li>
									<li>
										<a
											target="_blank"
											href="https://cmsqualitysupport.servicenowservices.com/qnet_qa?kb_id=916e86d5db84409082823638fc961991&id=kb_view2"
											>Inpatient Psychiatric Facilities</a
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</header>
	`,
})
export class PublicHeaderComponent implements OnInit {
	hideSearch: boolean = false;
	hideNavItems: boolean = false;

	constructor(private router: Router,private tealium: TealiumUtagService) {}

	ngOnInit() {
		this.hideNavItems = this.router.url === '/request-rejected';
		this.hideSearch = this.router.url === '/search' || this.hideNavItems;
		this.accessibility();
		this.searchListener(this.router);
	}

	searchListener(router): void {
		const component = this;
		$(document).ready(function () {
			$('input.search-bar').on('keypress', function (event) {
				if (event.which === 13) {
					router.navigate(['/search'], {
						queryParams: { q: event.currentTarget.value },
					});
				}
			});

			$('a.search-button').on('click', function (event) {
				event.preventDefault();
				component.trackSearch($('input.search-bar')[0].value);
				router.navigate(['/search'], {
					queryParams: { q: $('input.search-bar')[0].value },
				});
			});
		});
	}

	trackSearch(searchTerm: string): void {
		this.tealium.view({
		  tealium_event: 'search',
		  search_term: searchTerm,
		});
	  }

	accessibility(): void {
		// accessibility for public site
		$(document).ready(function () {
			// skip nav
			$('#skipNav').click(function () {
				if ($('.breadcrumbs a:first').length) {
					$('.breadcrumbs a:first').focus();
				} else {
					$('.home-banner a:first').focus();
				}
			});

			// set focus for quality programs menu (508/accessibility)
			$('.qnp-nav li:first button').click(function () {
				$('.quality-programs-menu .close-link').focus();
			});

			$('.quality-programs-menu .ds-l-lg-col--4:last-child ul:last-child li:last-child a').on(
				'keydown',
				function (e) {
					if (e.which === 9) {
						$('.quality-programs-menu .close-link').focus();
						e.preventDefault();
					}
				}
			);

			$('.quality-programs-menu .close-link').click(function () {
				$('.qnp-nav li:eq(0) button').focus();
			});

			// set focus for help menu (508/accessibility)
			$('.qnp-nav li:eq(1) button').click(function () {
				$('.help-menu .close-link').focus();
			});

			$('.help-menu .ds-l-lg-col--4:last-child ul:last-child li:last-child a').on(
				'keydown',
				function (e) {
					if (e.which === 9) {
						$('.help-menu .close-link').focus();
						e.preventDefault();
					}
				}
			);

			$('.help-menu .close-link').click(function () {
				$('.qnp-nav li:eq(1) button').focus();
			});

			// add screenreader-only text to links that open in new tab (508/accessibility)
			$("a[target='_blank']").append('<span class="sr-only"> - Opens in new browser tab</span>');

			// change last td in files table header into th with screenreader-only text (508/accessibility)
			$('.files-table thead tr td:nth-last-child(1)').replaceWith(
				'<th scope="col"><span class="sr-only">Action</span></th>'
			);
			$('.resource-table thead tr td:nth-last-child(1)').replaceWith(
				'<th scope="col"><span class="sr-only">Action</span></th>'
			);

			// add aria-haspopup to primary nav items
			$('.qnp-nav li:eq(0) button').attr('aria-haspopup', 'true');
			$('.qnp-nav li:eq(1) button').attr('aria-haspopup', 'true');
		});
	}
}
