import { Subject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { IFilterSelection } from '../public/hospitals-at-home/hospital-at-home';
import { APP_CONFIG, IAppEnvConfig } from '@qnp/qnp-common';

@Injectable()
export class HospitalAtHomeService {
	constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: IAppEnvConfig) {}
	public refreshSubmissionHistory: Subject<void> = new Subject();
	private hospitalMeasureSelectionSource$: BehaviorSubject<any> = new BehaviorSubject({
		filterSelected: false,
		filterType: 'Yes',
	});

	publicContentApiUrl = `${this.config.publicGateway}${this.config.publicContentApi}`;
	//publicContentApiUrl = `http://localhost:3000/public`;

	submitHopitalAtHomeRequest(payload) {
		const url = `${this.publicContentApiUrl}/homeHospital/${payload.issueType}`;
		return this.http.post(url, payload);
	}

	getHospitalMeasureSelection() {
		return this.hospitalMeasureSelectionSource$.asObservable();
	}

	setHospitalMeasureSelection(filter: IFilterSelection) {
		this.hospitalMeasureSelectionSource$.next(filter);
	}

	getHospitalInfo(payload) {
		const url = `${this.publicContentApiUrl}/homeHospital/search`;
		return this.http.post(url, payload);
	}

	submitHopitalAtHomeMeasure(payload) {
		const url = `${this.publicContentApiUrl}/homeHospital/measures/${payload.issueType}`;
		return this.http.post(url, payload);
	}

	getSubmissionHistory(ccn: string) {
		const url = `${this.publicContentApiUrl}/homeHospital/search`;
		return this.http.post(url, { ccn });
	}
}
