import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PageService } from '../../services/page.service';
import { Title } from '@angular/platform-browser';
import { PagerService, ToastrService } from '@qnp/qnp-common';

@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit, OnDestroy {
	searchTerm$ = new Subject<string>();
	subscription: Subscription;
	pagedItems: any[];
	pageSource$: any = new Subject<any>();
	pager: any = {};
	currentActivePage: number;

	newsResults: any = [];

	constructor(
		private toastr: ToastrService,
		private publicService: PageService,
		private pagerService: PagerService,
		private titleService: Title
	) {
		this.subscription = new Subscription();
	}

	ngOnInit() {
		this.titleService.setTitle('QualityNet News');
		this.listenForPageSelection();

		this.publicService.getNewsArticles(1).subscribe(
			(results: any) => {
				this.newsResults = results;
				this.setPageConfig();
			},
			error => this.toastr.error('Unable to load News Articles. Please try refreshing the page!')
		);
	}
	private listenForPageSelection() {
		this.pageSource$
			.asObservable()
			.pipe(switchMap((pageEvent: any) => this.publicService.getNewsArticles(pageEvent.page)))
			.subscribe(
				(results: any) => {
					this.newsResults = results;
					this.setPageConfig();
				},
				error => this.toastr.error('Unable to load News Articles. Please try refreshing the page!')
			);
	}
	private setPageConfig() {
		this.pagedItems = this.newsResults.data;
		this.pager = this.pagerService.getPager(
			this.newsResults.total,
			this.newsResults.page,
			this.newsResults.itemsPerPage
		);
	}
	/**
	 * This method takes the current page number from UI and lists the items correspondingly.
	 * @param page
	 */
	setPage(page: number) {
		if (!page || page === this.currentActivePage) return;
		this.currentActivePage = page;
		this.pageSource$.next({ page });
	}

	ngOnDestroy() {
		this.pagerService.resetCurrentPage();
		this.subscription.unsubscribe();
	}
}
