// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-msg {
	background-color: #e6ffe6;
	border-radius: 8px;
	max-width: 580px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	animation: fadein 3s;
	display: flex;
	justify-content: center;
	border: 2px solid var(--success-green);
	animation: fadeInBorder 3s forwards;
}

@keyframes fadeInBorder {
	0% {
		border-color: transparent;
	}
	100% {
		border-color: var(--success-green);
	}
}

.checkmark {
	width: 40px;
	height: 40px;
	display: inline-block;
	stroke-width: 5px;
	stroke: var(--success-green);
	stroke-miterlimit: 10;
	fill: none;
	margin-left: 5px;
	margin-bottom: 1px;
	animation: fadeinCheck 0.3s forwards;
}

.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: var(--success-green);
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
`, "",{"version":3,"sources":["webpack://./src/app/public/cag-alzheimer/cag-landing-page/cag-landing-success-page.component.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,kBAAkB;CAClB,gBAAgB;CAChB,+CAA+C;CAC/C,oBAAoB;CACpB,aAAa;CACb,uBAAuB;CACvB,sCAAsC;CACtC,mCAAmC;AACpC;;AAEA;CACC;EACC,yBAAyB;CAC1B;CACA;EACC,kCAAkC;CACnC;AACD;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,qBAAqB;CACrB,iBAAiB;CACjB,4BAA4B;CAC5B,qBAAqB;CACrB,UAAU;CACV,gBAAgB;CAChB,kBAAkB;CAClB,oCAAoC;AACrC;;AAEA;CACC,qBAAqB;CACrB,sBAAsB;CACtB,eAAe;CACf,qBAAqB;CACrB,4BAA4B;CAC5B,UAAU;CACV,8DAA8D;AAC/D;;AAEA;CACC,yBAAyB;CACzB,oBAAoB;CACpB,qBAAqB;CACrB,mEAAmE;AACpE;;AAEA;CACC;EACC,oBAAoB;CACrB;AACD","sourcesContent":[".success-msg {\n\tbackground-color: #e6ffe6;\n\tborder-radius: 8px;\n\tmax-width: 580px;\n\tbox-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);\n\tanimation: fadein 3s;\n\tdisplay: flex;\n\tjustify-content: center;\n\tborder: 2px solid var(--success-green);\n\tanimation: fadeInBorder 3s forwards;\n}\n\n@keyframes fadeInBorder {\n\t0% {\n\t\tborder-color: transparent;\n\t}\n\t100% {\n\t\tborder-color: var(--success-green);\n\t}\n}\n\n.checkmark {\n\twidth: 40px;\n\theight: 40px;\n\tdisplay: inline-block;\n\tstroke-width: 5px;\n\tstroke: var(--success-green);\n\tstroke-miterlimit: 10;\n\tfill: none;\n\tmargin-left: 5px;\n\tmargin-bottom: 1px;\n\tanimation: fadeinCheck 0.3s forwards;\n}\n\n.checkmark__circle {\n\tstroke-dasharray: 166;\n\tstroke-dashoffset: 166;\n\tstroke-width: 2;\n\tstroke-miterlimit: 10;\n\tstroke: var(--success-green);\n\tfill: none;\n\tanimation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;\n}\n\n.checkmark__check {\n\ttransform-origin: 50% 50%;\n\tstroke-dasharray: 48;\n\tstroke-dashoffset: 48;\n\tanimation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;\n}\n\n@keyframes stroke {\n\t100% {\n\t\tstroke-dashoffset: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
