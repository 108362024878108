import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from '../../services/page.service';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { TealiumUtagService, ToastrService } from '@qnp/qnp-common';

@Component({
	selector: 'app-files',
	templateUrl: './files.component.html',
	styleUrls: ['./files.component.css'],
})
export class FilesComponent implements OnInit {
	pageHtml: any;
	filename: string;
	constructor(
		private publicContentService: PageService,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		public router: Router,
		private tealium: TealiumUtagService
	) {}

	ngOnInit() {
		this.trackAnalytics();
		this.getFile();
	}

    private trackAnalytics(){
        const fullUrl = `${window.location.origin}${this.router.url}`;
        const urlParams = new URL(fullUrl).searchParams;
        const filename = urlParams.get('filename') || 'unknown_file';
        const fileExtension = this.getFileExtension(filename);
        const linkText = `Download ${filename}`;
		this.tealium.link({
            event_name: "file_download",
            file_name: filename,
            file_extension: fileExtension,
            link_type: "link_download",
            link_url: fullUrl,
            text: linkText
        });
	}

	private getFileExtension(filename: string): string {
        return filename.split('.').pop();
    }

	getFile() {
		const id = this.route.snapshot.paramMap.get('id');
		this.publicContentService.getFileById(id).subscribe(
			(res: any) => {
				this.filename = res.fileName || '';
				this.publicContentService.getS3File(res.downloadUrl).subscribe(data => {
					if ((<any>window.navigator).msSaveBlob) {
						(<any>window.navigator).msSaveBlob(data['body'], this.filename);
					} else {
						FileSaver.saveAs(data['body'], this.filename);
					}
				});
			},
			err => {
				this.toastr.error('Error 404: Page not found');
				this.router.navigate(['']);
			}
		);
	}
}
