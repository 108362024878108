import { Component } from '@angular/core';

@Component({
	selector: 'app-public-footer',
	template: `
		<section class="subfooter">
			<div class="ds-l-container">
				<div class="ds-l-row">
					<div class="ds-l-col--12 ds-u-text-align--center">
						Join the ListServe to receive email notifications about QualityNet programs&nbsp;&nbsp;
						<a href="/listserv-signup" class="ds-c-button ds-c-button--inverse">Join Now</a>
					</div>
				</div>
			</div>
		</section>
		<footer class="qnp-footer ds-base--inverse">
			<div class="ds-l-container">
				<div class="ds-l-row">
					<div class="ds-l-md-col--10 footer-content">
						<div class="qualitynet-logo">
							<img src="/images/QualityNet-logo-white.svg" alt="CMS.gov QualityNet logo" />
						</div>
						<div class="toggle-content">
							<hr />
							<div class="ds-l-row footer-links">
								<div class="ds-l-md-col--12 ds-l-col--6">
									<ul>
										<li><a href="http://cms.gov">CMS.gov</a></li>
										<li><a href="http://qualitynet.cms.gov">QualityNet</a></li>
										<li><a href="/support">QualityNet Service Center</a></li>
										<li><a href="/getting-started">Help</a></li>
									</ul>
								</div>
								<div class="ds-l-md-col--12 ds-l-col--6">
									<ul>
										<li><a href="/accessibility">Accessibility</a></li>
										<li><a href="/privacy-policy">Privacy Policy</a></li>
										<li><a href="/terms-of-use">Terms of Use</a></li>
										<li>
											<a href="https://ows.io/qs/2swpwpyy" target="_blank">Give Us Feedback</a>
										</li>
									</ul>
								</div>
							</div>
							<hr class="border-bottom" />
						</div>
					</div>
					<div class="ds-l-md-col--2 hhs-logo">
						<img
							src="/images/hhs-logo-white.svg"
							alt="U.S. Department of Health & Human Services logo"
						/>
					</div>
				</div>
			</div>
		</footer>
	`,
})
export class PublicFooterComponent {}
