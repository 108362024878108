import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-public-error',
	templateUrl: './error-component.html',
	styleUrls: ['./error-component.css'],
})
export class PublicErrorComponent implements OnInit {
	errorMessage: string = `There was a problem submitting your request. Please copy/paste the code or take a screen capture
    of this error with the code and provide to the Service Center for resolution.`;
	errorCode: string;

	constructor(private route: ActivatedRoute) {}

	ngOnInit() {
		this.errorCode = this.route.snapshot.paramMap.get('id');
	}
}
