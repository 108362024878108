// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
	font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/app/public/vaccine-attestation/nursing-home-form/nursing-home-form.component.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB","sourcesContent":["label {\n\tfont-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
