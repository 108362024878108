import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PageService } from '../../services/page.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ToastrService } from '@qnp/qnp-common';

declare var $: any;

@Component({
	selector: 'app-page',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.css'],
})
export class PageComponent implements OnInit {
	pageHtml: any;
	isLoading: boolean = false;

	constructor(
		private publicContentService: PageService,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private sanitizer: DomSanitizer,
		private titleService: Title,
		public router: Router,
		public location: Location
	) {}

	ngOnInit() {
		this.route.url.subscribe(url => {
			const urlString = url.join('/').toString();
			this.pageHtml = '';
			this.isLoading = true;
			if (urlString.includes('previews')) {
				this.getPreviewPage(urlString);
				return;
			}
			this.getPageByPermalink(urlString);
		});
	}

	getPageByPermalink(urlString) {
		this.publicContentService.getPageByPermalink(urlString).subscribe(
			(res: any) => {
				this.servePages(res);
			},
			err => {
				this.handlePageNotFound();
			}
		);
	}

	getPreviewPage(urlString) {
		const pageId = urlString.split('/')[1];
		this.publicContentService.getPreviewPage(pageId).subscribe(
			(res: any) => {
				this.servePages(res);
			},
			err => {
				this.handlePageNotFound();
			}
		);
	}

	servePages(res) {
		this.titleService.setTitle(res.headers.get('X-Page-Title'));
		this.pageHtml = this.sanitizer.bypassSecurityTrustHtml(res.body.toString());
		this.pageHtml = !!this.pageHtml ? this.pageHtml : '';
		this.deeplink();
		this.accessibility();
		this.searchListener(this.router);
		this.isLoading = false;
	}

	handlePageNotFound() {
		this.isLoading = false;
		this.toastr.error('Error 404: Page not found');
		this.router.navigate(['']);
	}

	searchListener(router): void {
		$(document).ready(function () {
			$('input.search-bar').on('keypress', function (event) {
				if (event.which === 13) {
					router.navigate(['/search'], {
						queryParams: { q: event.currentTarget.value },
					});
				}
			});

			$('a.search-button').on('click', function (event) {
				event.preventDefault();
				router.navigate(['/search'], {
					queryParams: { q: $('input.search-bar')[0].value },
				});
			});
		});
	}

	deeplink(): void {
		// jquery for public site
		$(document).ready(function () {
			// add active class to active secondary nav
			const fullPath = location.href.split('#')[0];
			$('.secondary-nav li a').each(function () {
				const $this = $(this);
				if (encodeURI($this.prop('href').split('#')[0]) === fullPath) {
					$this.parent('li').addClass('active');
				}
			});

			if (location.hash) {
				const hash = location.hash.split('#')[1];
				// make Bootstrap tabs deep-linkable
				$('#v-pills-tab a[href="#' + hash + '"]').tab('show');
				history.replaceState(null, null, location.href);
				setTimeout(() => {
					$(window).scrollTop(0);
				}, 400);
			}

			// load CPT modal on page load
			$('#cptModal').modal('show');

			$('a[data-toggle="pill"]').on('click', function () {
				let newUrl;
				const hash = $(this).attr('href');
				if (
					hash === '#tab1' ||
					'#tab2' ||
					'#tab3' ||
					'#tab4' ||
					'#tab5' ||
					'#tab6' ||
					'#tab7' ||
					'#tab8' ||
					'#tab9' ||
					'#tab10'
				) {
					newUrl = location.href.split('#')[0] + hash;
				} else {
					newUrl = location.href.split('#')[0];
				}
				history.replaceState(null, null, newUrl);
			});
		});
	}

	accessibility(): void {
		// accessibility for public site
		$(document).ready(function () {
			// skip nav
			$('#skipNav').click(function () {
				if ($('.breadcrumbs a:first').length) {
					$('.breadcrumbs a:first').focus();
				} else {
					$('.home-banner a:first').focus();
				}
			});

			// set focus for quality programs menu (508/accessibility)
			$('.qnp-nav li:first button').click(function () {
				$('.quality-programs-menu .close-link').focus();
			});

			$('.quality-programs-menu .ds-l-lg-col--4:last-child ul:last-child li:last-child a').on(
				'keydown',
				function (e) {
					if (e.which === 9) {
						$('.quality-programs-menu .close-link').focus();
						e.preventDefault();
					}
				}
			);

			$('.quality-programs-menu .close-link').click(function () {
				$('.qnp-nav li:eq(0) button').focus();
			});

			// set focus for help menu (508/accessibility)
			$('.qnp-nav li:eq(1) button').click(function () {
				$('.help-menu .close-link').focus();
			});

			$('.help-menu .ds-l-lg-col--4:last-child ul:last-child li:last-child a').on(
				'keydown',
				function (e) {
					if (e.which === 9) {
						$('.help-menu .close-link').focus();
						e.preventDefault();
					}
				}
			);

			$('.help-menu .close-link').click(function () {
				$('.qnp-nav li:eq(1) button').focus();
			});

			// add screenreader-only text to links that open in new tab (508/accessibility)
			$("a[target='_blank']").append('<span class="sr-only"> - Opens in new browser tab</span>');

			// change last td in files table header into th with screenreader-only text (508/accessibility) .
			$('.resource-table thead tr').append('<td>&nbsp;</td>');
			$('.files-table thead tr').append('<td>&nbsp;</td>');

			$('.files-table thead tr td:nth-last-child(1)').replaceWith(
				'<th scope="col"><span class="sr-only">Action</span></th>'
			);

			$('.resource-table thead tr td:nth-last-child(1)').replaceWith(
				'<th scope="col"><span class="sr-only">Action</span></th>'
			);

			// add aria-haspopup to primary nav items
			$('.qnp-nav li:eq(0) button').attr('aria-haspopup', 'true');
			$('.qnp-nav li:eq(1) button').attr('aria-haspopup', 'true');
		});
	}
}
