import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'app-acc-form',
	templateUrl: './acc-form.component.html',
	styleUrls: ['./acc-form.component.css'],
})
export class AccFormComponent implements OnInit {
	@Input() accForm: any;
	vaccinationRateDetails = [
		{
			title: 'What is your facility’s overall percentage of staff vaccinated?',
			controlName: 'pq19',
		},
		{
			title: 'How many staff meet the requirement to be vaccinated?',
			controlName: 'pq20',
		},
		{
			title: 'How many staff have a medical exemption?',
			controlName: 'pq21',
		},
		{
			title: 'How many staff have a non-medical exemption?',
			controlName: 'pq22',
		},
		{
			title: 'How many staff have an approved temporary delay?',
			controlName: 'pq23',
		},
		{
			title: 'How many staff are not included in any of these categories?',
			controlName: 'pq24',
		},
	];
	constructor() {}

	ngOnInit(): void {
		this.listenForAccFormChanges();
	}

	private listenForAccFormChanges() {
		const pq1Control = <UntypedFormControl>this.accForm.get('pq1');
		this.addDynamicRadioControls(pq1Control, 'pq2');
		const pq3Control = <UntypedFormControl>this.accForm.get('pq3');
		this.addDynamicRadioControls(pq3Control, 'pq4');
		const pq5Control = <UntypedFormControl>this.accForm.get('pq5');
		this.addDynamicRadioControls(pq5Control, 'pq6');
		const pq9Control = <UntypedFormControl>this.accForm.get('pq9');
		this.addDynamicRadioControls(pq9Control, 'pq10');
	}

	private addDynamicRadioControls(control, newControl) {
		control.valueChanges
			.pipe(
				tap(value => {
					if (value === 'Yes') {
						this.accForm.addControl(newControl, new UntypedFormControl('', [Validators.required]));
					} else {
						this.accForm.removeControl(newControl);
					}
				})
			)
			.subscribe();
	}

	omitNumber(event, field?) {
		const key = event.which;
		const rejectKeys = [43, 45, 101];
		if (field !== 'pq19') rejectKeys.push(46);
		if (rejectKeys.includes(key)) return false;
	}
}
