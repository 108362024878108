import { Component, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
	UntypedFormControl,
} from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { tap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { Title } from '@angular/platform-browser';
import { HospitalAtHomeService } from '../../../services/hospital-at-home.service';
import { FormService } from '../../../services/form.service';
import { attestationTitles } from '../hospital-at-home';

@Component({
	selector: 'app-hospitals-at-home',
	templateUrl: './hospitals-at-home.component.html',
	styleUrls: ['./hospitals-at-home.component.css'],
})
export class HospitalsAtHomeComponent implements OnInit {
	hospitalsAtHomeForm: UntypedFormGroup;
	reCaptchaToken: string;
	showTier1: boolean;
	tierSelected: boolean;
	showErrorBanner: boolean = false;
	isLoading: boolean = false;
	isCaptchaValid: boolean = false;

	constructor(
		public formBuilder: UntypedFormBuilder,
		private hosptalSvc: HospitalAtHomeService,
		private formSvc: FormService,
		private router: Router,
		private titleService: Title
	) {}

	// Method invoked after the reCaptcha puzzel is solved.
	// We store the reCaptcha Token in the ProfileInfo form object.
	resolved(captchaResponse: string) {
		this.isCaptchaValid = !isEmpty(captchaResponse);
		this.reCaptchaToken = captchaResponse;
	}

	ngOnInit() {
		const featureFlag = this.checkFeatureFlags('waivers');
		this.titleService.setTitle('Acute Hospital Care at Home Waiver');
		this.initializeFormGroup();
		this.listenFortierTypeForm();
		this.formSvc.syncWithLocalStorage(
			this.hospitalsAtHomeForm,
			'ahcah-waiver-form',
			null,
			null,
			false
		);
		if (this.formSvc.checkItemExists('ahcah-waiver-form')) {
			this.hospitalsAtHomeForm.markAllAsTouched();
			this.hospitalsAtHomeForm.updateValueAndValidity();
		}
	}

	checkFeatureFlags(type: string) {
		this.hosptalSvc.checkFeatureFlag(type).subscribe(
			res => {
				this.isLoading = false;
			},
			err => {
				this.isLoading = false;
				this.router.navigate(['acute-hospital-care-at-home']);
			}
		);
	}

	initializeFormGroup() {
		this.hospitalsAtHomeForm = this.formBuilder.group(
			{
				hospitalInfo: this.initializeHospitalInfoForm(),
				pointOfContact: this.initializePointOFContactForm(),
				issueType: this.initializeQuestionaireForm(),
				attestation: this.initializeAttestationForm(),
			},
			{ updateOn: 'blur' }
		);
	}

	listenFortierTypeForm() {
		this.hospitalsAtHomeForm.controls.issueType.valueChanges
			.pipe(
				tap(({ issueType }) => {
					this.showTier1 = issueType === 'yes';
					this.addTierFormGroup(this.showTier1);
				})
			)
			.subscribe();
	}

	initializeHospitalInfoForm() {
		return new UntypedFormGroup({
			ccn: new UntypedFormControl('', [Validators.required, Validators.pattern('^(\\d{6})$')]),
			hospitalName: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'.\\s]*$`),
			]),
			hospitalPhone: new UntypedFormControl('', [
				Validators.pattern(`\\(?\\d{3}\\)?-? *\\d{3}-? *-?\\d{4}`),
			]),
			addressLineOne: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'.#,\\s]*$`),
			]),
			addressLineTwo: new UntypedFormControl('', [
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'.#,\\s]*$`),
			]),
			cityAddress: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'.\\s]*$`),
			]),
			stateAddress: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			zipCode: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern('^(\\d{5}(-\\d{4})?|[A-Z]\\d[A-Z] *\\d[A-Z]\\d)$'),
			]),
		});
	}

	initializePointOFContactForm() {
		return new UntypedFormGroup({
			pocName: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'/.\\s]*$`),
			]),
			pocEmail: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$`),
			]),
			pocConfirmEmail: new UntypedFormControl('', [
				Validators.required,
				this.emailMatchConfirmValidator,
			]),
			pocPhone: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern(`\\(?\\d{3}\\)?-? *\\d{3}-? *-?\\d{4}`),
			]),
			pocPhoneExt: new UntypedFormControl('', [Validators.pattern(`\\d{4}`)]),
		});
	}

	emailMatchConfirmValidator = () => {
		const pointOfContactForm = this?.hospitalsAtHomeForm?.controls[
			'pointOfContact'
		] as UntypedFormGroup;
		return pointOfContactForm?.controls['pocEmail']?.value ===
			pointOfContactForm?.controls['pocConfirmEmail']?.value
			? null
			: { mismatch: true };
	};

	initializeQuestionaireForm() {
		return new UntypedFormGroup({
			issueType: new UntypedFormControl('', {
				validators: [Validators.required],
				updateOn: 'change',
			}),
		});
	}

	initializeAttestationForm() {
		return new UntypedFormGroup({
			notes: new UntypedFormControl('', [
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			attestingName: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'/.\\s]*$`),
			]),
			attestingEmail: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(`[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$`),
			]),
			attestingPhone: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern(`\\(?\\d{3}\\)?-? *\\d{3}-? *-?\\d{4}`),
			]),
			attestingTitle: new UntypedFormControl('', [Validators.required]),
			otherTitle: new UntypedFormControl('', [
				Validators.maxLength(255),
				Validators.pattern(`^[a-zA-Z0-9\\-'.\\s]*$`),
			]),
		});
	}

	addTierFormGroup(isTier1: boolean) {
		if (this.hospitalsAtHomeForm.contains('tier')) {
			this.hospitalsAtHomeForm.removeControl('tier');
		}
		const formGroup = isTier1 ? this.getTier1Form() : this.getTier2Form();
		this.hospitalsAtHomeForm.addControl('tier', formGroup);
		this.tierSelected = true;
	}

	getTier1Form() {
		return new UntypedFormGroup({
			q2: new UntypedFormControl('', [Validators.required]),
			q3: new UntypedFormControl('', [Validators.required]),
			q4: new UntypedFormControl('', [Validators.required]),
			q5: new UntypedFormControl('', [Validators.required]),
			q6: new UntypedFormControl('', [Validators.required]),
			q7: new UntypedFormControl('', [Validators.required]),
			q8: new UntypedFormControl('', [Validators.required]),
			q9: new UntypedFormControl('', [Validators.required]),
			q10: new UntypedFormControl('', [Validators.required]),
			q11: new UntypedFormControl('', [Validators.required]),
			q12: new UntypedFormControl('', [Validators.required]),
		});
	}

	getTier2Form() {
		return new UntypedFormGroup({
			q2: new UntypedFormControl('', [
				Validators.required,
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q3: new UntypedFormControl('', [Validators.required]),
			q4: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q5: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q6: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q7: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q8: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q9: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q10: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q11: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q12: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q13: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q14: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q15: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q16: new UntypedFormControl('', [Validators.required]),
			q17: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q18: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q19: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q20: new UntypedFormControl('', [Validators.required]),
			q21: new UntypedFormControl('', [Validators.required]),
			q22: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q23: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q24: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q25: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
			q26: new UntypedFormControl('', [
				Validators.required,
				Validators.maxLength(32767),
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
			]),
		});
	}

	submitHospitalAtHomeForm() {
		this.isLoading = true;
		const payload = this.showTier1
			? this.getTierOneInfo(this.hospitalsAtHomeForm.value)
			: this.getTierTwoInfo(this.hospitalsAtHomeForm.value);
		this.hosptalSvc.submitHopitalAtHomeRequest(payload).subscribe(
			res => {
				this.isLoading = false;
				this.showErrorBanner = false;
				this.formSvc.removeFromLocalStorage('ahcah-waiver-form');
				const navigationExtras: NavigationExtras = {
					state: res,
				};
				this.router.navigate(['acute-hospital-care-at-home/waiver-success'], navigationExtras);
			},
			err => {
				this.isLoading = false;
				this.showErrorBanner = true;
			}
		);
	}

	formatAttestationDetails(attestationPayload) {
		if (attestationPayload.attestingTitle === attestationTitles[5]) {
			attestationPayload.attestingTitle = attestationPayload.otherTitle;
			delete attestationPayload.otherTitle;
		} else {
			delete attestationPayload.otherTitle;
		}
		return attestationPayload;
	}

	getTierOneInfo({ attestation, hospitalInfo, pointOfContact, tier, issueType: { issueType } }) {
		const tierType = issueType === 'yes' ? 'tierOne' : 'tierTwo';
		attestation = this.formatAttestationDetails(attestation);
		return {
			issueType: tierType,
			recaptchaToken: this.reCaptchaToken,
			commonText: {
				...hospitalInfo,
				...pointOfContact,
				...attestation,
			},
			dropdowns: {
				q1: tierType === 'tierOne',
				...tier,
			},
		};
	}
	getTierTwoInfo({ attestation, hospitalInfo, pointOfContact, tier, issueType: { issueType } }) {
		const tierType = issueType === 'yes' ? 'tierOne' : 'tierTwo';
		const dropdownValues = this.getDropdownValues(tier);
		const tierTwoTextFields = this.getTierTwoTextFields(tier);
		attestation = this.formatAttestationDetails(attestation);
		return {
			issueType: tierType,
			recaptchaToken: this.reCaptchaToken,
			commonText: {
				...hospitalInfo,
				...pointOfContact,
				...attestation,
			},
			dropdowns: {
				q1: tierType === 'tierOne',
				...dropdownValues,
			},
			tierTwoTextFields,
		};
	}

	getDropdownValues({ q2, q3, q16, q20, q21 }) {
		return { q2, q3, q16, q20, q21 };
	}

	getTierTwoTextFields({
		q4,
		q5,
		q6,
		q7,
		q8,
		q9,
		q10,
		q11,
		q12,
		q13,
		q14,
		q15,
		q17,
		q18,
		q19,
		q22,
		q23,
		q24,
		q25,
		q26,
	}) {
		return {
			q4,
			q5,
			q6,
			q7,
			q8,
			q9,
			q10,
			q11,
			q12,
			q13,
			q14,
			q15,
			q17,
			q18,
			q19,
			q22,
			q23,
			q24,
			q25,
			q26,
		};
	}
}
